import Waveform from '@Molecule/WaveForm'
import Images from '@Theme/Images'
import Styles from './style.module.scss'

export default function VoiceCard({
  imgUrl,
  audioUrl = ''
}) {


  return (
    <div className={Styles.container}>
      <img src={imgUrl || Images.AVA_DEFAULT} alt='' />
      {/* <AudioPlayer audioUrl={audioUrl} /> */}
      <Waveform
        url={audioUrl}
      />

    </div>
  )
}