import { API } from "configs";

export function fetchAllCoordinatorExternal(page = 1, limit = 10) {
  return new Promise(async (resolve, reject) => {
    const client = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const response = await API.get(
        `/externalcoordinator?page=${page}&limit=${limit}&clientCode=${client?.code}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
