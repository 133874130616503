import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import Styles from "./style.module.scss";
import SearchBar from "@Atom/SearchBar";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import ProfileEmployee from "@Atom/ProfileEmployee";
import Toast from "@Atom/Toast";
import InputField from "@Atom/InputField";
import { getAllMembersForSetSquad } from "@Services/workLocation/getAllMembersForSetSquad";
import AvatarImages from "@Assets/Images/ava-default.png";
import { useDebounce } from "@Hooks/useDebounce";
import { setMembersPersonil } from "@Services/workLocation/setMembersPersonil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "@Atom/Spinner";
import { createSquad } from "@Services/squad/createSquad";
import { getSyncSquadPerLocation } from "@Services/squad/getSyncSquadPerLocation";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import useWindowSize from "@Hooks/useWindowSize";
import AVA_DEFAULT from "@Assets/Images/ava-default.png";
import { updateSquadForSetUp } from "@Services/squad/updateSquadForSetUp";
export default function ModalAddMembers({
  isOpen = false,
  isTransition = false,
  onClose = () => {},
  tabsActive = 0,
  isFromEllipsisMenu = false,
  templateListSquad,
  dataSelectedForSetUp = {
    profilePicture: "",
    name: "Placeholder",
    level: "Staff",
    id: "",
  },
}) {
  const { height } = useWindowSize();
  const { id: locationID } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");
  const [isCheckedPersonils, setIsCheckedPersonils] = useState([]);
  const [isCheckedSquad, setIsCheckedSquad] = useState([]);

  const [isActiveRadio, setIsActiveRadio] = useState(null);

  useEffect(() => {
    if (isFromEllipsisMenu) {
      setIsActiveRadio(dataSelectedForSetUp);
    }
  }, [dataSelectedForSetUp, isFromEllipsisMenu]);

  const [isCheckedSquadDanru, setIsCheckedSquadDanru] = useState([]);

  const nameStateTabsSquad = "activeListSquadFilterTabs";
  const nameStateTabsPersonil = "activeListPersonilFilterTabs";

  // const nameStateTabsPersonil = "activeListPersonilFilterTabs";

  const [activeListSquadFilterTabs, setIsActiveListSquadFilterTabs] = useState(
    +sessionStorage.getItem(nameStateTabsSquad) || 0
  );

  const [activeListPersonilFilterTabs, setactiveListPersonilFilterTabs] =
    useState(+sessionStorage.getItem(nameStateTabsPersonil) || 0);

  useEffect(() => {
    sessionStorage.setItem(nameStateTabsSquad, activeListSquadFilterTabs);
  }, [activeListSquadFilterTabs]);

  useEffect(() => {
    sessionStorage.setItem(nameStateTabsPersonil, activeListPersonilFilterTabs);
  }, [activeListPersonilFilterTabs]);

  const listSquadFilterTabs = ["Secpri/Secwan", "Danru"];
  // const listStaffFilterTabs = ["Secpri/Secwan", "Danru"];

  const [dataStaffPersonil, setDataStaffPersonil] = useState(); //STAFF SQUAD AS PERSONIL
  const [dataDanruPersonil, setDataDanruPersonil] = useState(); //DANRU SQUAD AS PERSONIL

  const [dataStaffSquad, setDataStaffSquad] = useState(); //STAFF SQUAD AS REGU
  const [dataDanruSquad, setDataDanruSquad] = useState(); //DANRU SQUAD AS REGU

  const page = 1;
  const [limitPersonilForStaff, setLimitPersonilForStaff] = useState(10);
  const [limitPersonilForDanru, setLimitPersonilForDanru] = useState(10);

  const [limitSquadForStaff, setLimitSquadForStaff] = useState(10);
  const [limitSquadForDanru, setLimitSquadForDanru] = useState(10);

  const [searchSquad, setSearchSquad] = useState("");
  const debounceQuerySquad = useDebounce(searchSquad, 500);

  const [searchPersonil, setSearchPersonil] = useState("");
  const debounceQueryPersonil = useDebounce(searchPersonil, 500);

  const [loadingGetPersonil, setLoadingGetPersonil] = useState(true);
  const [loadingGetSquad, setLoadingGetSquad] = useState(true);

  const getAllPersonilForSet = useCallback(
    async (type = "danru") => {
      setLoadingGetPersonil(true);
      try {
        const res = await getAllMembersForSetSquad(
          type,
          decodeURIComponent(CLIENT_KEY.code),
          page,
          activeListPersonilFilterTabs === 0
            ? limitPersonilForStaff
            : limitPersonilForDanru,
          debounceQueryPersonil
        );
        if (res.status) {
          if (type === "staff") {
            setDataStaffPersonil(res.data);
          } else {
            setDataDanruPersonil(res.data);
          }
        }
      } catch (error) {
        console.log("error get sync personils: ", error);
      } finally {
        setLoadingGetPersonil(false);
      }
    },
    [
      CLIENT_KEY.code,
      activeListPersonilFilterTabs,
      limitPersonilForStaff,
      limitPersonilForDanru,
      debounceQueryPersonil,
      page,
    ]
  );

  useEffect(() => {
    getAllPersonilForSet("staff");
  }, [getAllPersonilForSet]);

  const getAllSquadForSet = useCallback(
    async (type = "danru") => {
      setLoadingGetSquad(true);
      try {
        const res = await getSyncSquadPerLocation(
          type,
          locationID,
          page,
          activeListSquadFilterTabs === 0
            ? limitSquadForStaff
            : limitSquadForDanru,
          debounceQuerySquad
        );
        if (res.status) {
          if (type === "staff") {
            setDataStaffSquad(res);
          } else {
            setDataDanruSquad(res);
          }
        }
      } catch (error) {
        console.log("error get sync squad: ", error);
      } finally {
        setLoadingGetSquad(false);
      }
    },
    [
      locationID,
      activeListSquadFilterTabs,
      limitSquadForStaff,
      limitSquadForDanru,
      debounceQuerySquad,
    ]
  );

  useEffect(() => {
    getAllSquadForSet("staff");
  }, [getAllSquadForSet]);

  useEffect(() => {
    if (activeListSquadFilterTabs === 0) {
      getAllSquadForSet("staff");
    } else {
      getAllSquadForSet("danru");
    }
  }, [activeListSquadFilterTabs, getAllSquadForSet]);

  useEffect(() => {
    if (activeListPersonilFilterTabs === 0) {
      getAllPersonilForSet("staff");
    } else {
      getAllPersonilForSet("danru");
    }
  }, [getAllPersonilForSet, activeListPersonilFilterTabs]);

  const [loadingPost, setLoadingPost] = useState(false);
  const [isSuccesAddPersonil, setIsSuccesAddPersonil] = useState(false);
  const [isErrorAddPersonil, setIsErrorAddPersonil] = useState(false);
  const [isSuccesAddSquad, setIsSuccesAddSquad] = useState(false);
  const [isErrorAddSquad, setIsErrorAddSquad] = useState(false);

  const handleSavePersonil = async () => {
    setLoadingPost(true);

    const dataToSend = isCheckedPersonils?.map((el) => ({
      name: el?.name,
      employeeID: el.idEmployee,
      role: el.role,
    }));

    try {
      const res = await setMembersPersonil(locationID, {
        members: dataToSend,
      });
      if (res.status === 200) {
        setLoadingPost(false);
        setIsSuccesAddPersonil(true);
        navigate(`${pathname}`, { replace: true });
        getAllPersonilForSet();
        setTimeout(() => {
          onClose();
          setIsCheckedPersonils([]);
        }, 3000);
      }
    } catch (error) {
      setIsErrorAddPersonil(true);
      setLoadingPost(false);
      console.log("error set add members personil:  ", error);
    }
  };

  const [squadName, setSquadName] = useState("");
  const [isEmptyNameSquad, setIsEmptyNameSquad] = useState(false);

  const [isErrorAlreadyExistSquadName, setIsErrorAlreadyExistSquadName] =
    useState(false);

  const handleSaveSquad = async () => {
    if (!squadName) {
      setIsEmptyNameSquad(true);
    } else {
      setLoadingPost(true);

      try {
        const res = await createSquad({
          name: squadName,
          locationID: locationID,
          danru: isActiveRadio.idEmployee,
          members: isCheckedSquad.map((s) => s.idEmployee),
        });
        if (res.status) {
          setIsSuccesAddSquad(true);
          navigate(pathname, { replace: true });
          getAllSquadForSet();
          setTimeout(() => {
            onClose();
            setIsCheckedSquad([]);
          }, 3000);
        }
      } catch (error) {
        const { data } = error.response;
        if (data.message === "Group name already exists in this location") {
          setIsErrorAlreadyExistSquadName(true);
        } else {
          setIsErrorAddSquad(true);
        }
        console.log("error set add squad :  ", error);
      } finally {
        setLoadingPost(false);
      }
    }
  };

  const dataToShow = useCallback(() => {
    if (tabsActive === 0) {
      if (activeListPersonilFilterTabs === 0) {
        return dataStaffPersonil?.data;
      } else {
        return dataDanruPersonil?.data;
      }
    } else {
      if (activeListSquadFilterTabs === 0) {
        return dataStaffSquad?.data;
      } else {
        return dataDanruSquad?.data;
      }
    }
  }, [
    activeListPersonilFilterTabs,
    activeListSquadFilterTabs,
    dataDanruPersonil,
    dataDanruSquad,
    dataStaffPersonil,
    dataStaffSquad,
    tabsActive,
  ]);

  const dataListPersonil = useMemo(() => {
    return {
      data:
        dataToShow()?.map((l) => ({
          id: l?._id || "-",
          name: capitalizeEachWord(l?.name) || "-",
          profilePicture: l?.profilePicture || AvatarImages,
          idEmployee: l?.employeeID || "-",
          role: capitalizeEachWord(l?.role) || "-",
          assignment: capitalizeEachWord(l?.assignTitle) || "-",
        })) || [],
      columns: [
        {
          name: "name",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Nama" />
            </div>
          ),
          renderData: (row) => (
            <ProfileEmployee
              idEmployee={row.idEmployee}
              image={row.profilePicture}
              name={row.name}
            />
          ),
        },
        {
          name: "level",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Jabatan" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.role}</span>
            </div>
          ),
        },
        {
          name: "assignment",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader isSorted text="Penugasan" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.assignment}</span>
            </div>
          ),
        },
      ],
    };
  }, [dataToShow]);

  const listInnerRefPersonil = useRef();
  const listInnerRefSquad = useRef();

  const onScrollPersonil = useCallback(() => {
    if (listInnerRefPersonil.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefPersonil.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (activeListPersonilFilterTabs === 0) {
          if (limitPersonilForStaff < dataStaffPersonil?.totalData)
            setLimitPersonilForStaff((staff) => staff + 10);
        } else {
          if (limitPersonilForDanru < dataDanruPersonil?.totalData)
            setLimitPersonilForDanru((danru) => danru + 10);
        }
      }
    }
  }, [
    activeListPersonilFilterTabs,
    dataDanruPersonil?.totalData,
    dataStaffPersonil?.totalData,
    limitPersonilForDanru,
    limitPersonilForStaff,
  ]);

  const onScrollSquad = useCallback(() => {
    if (listInnerRefSquad.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefSquad.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (activeListSquadFilterTabs === 0) {
          if (limitSquadForStaff < dataStaffSquad?.totalData)
            setLimitSquadForStaff((staff) => staff + 10);
        } else {
          if (limitSquadForDanru < dataDanruSquad?.totalData)
            setLimitSquadForDanru((danru) => danru + 10);
        }
      }
    }
  }, [
    activeListSquadFilterTabs,
    dataDanruSquad?.totalData,
    dataStaffSquad?.totalData,
    limitSquadForDanru,
    limitSquadForStaff,
  ]);

  useEffect(() => {
    if (height >= 768) {
      onScrollPersonil();
      onScrollSquad();
    }
  }, [height, onScrollPersonil, onScrollSquad]);

  const [sortSetUpForSquad, setSortSetUpForSquad] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });

  const sortedDataForSetUpSquad = useMemo(() => {
    const sorted = [...templateListSquad?.data].sort((a, b) => {
      if (sortSetUpForSquad.column) {
        if (sortSetUpForSquad.type === "ASC") {
          return a?.name?.localeCompare(b?.name);
        } else {
          return b?.name?.localeCompare(a?.name);
        }
      }
      return 0;
    });

    return sorted;
  }, [
    sortSetUpForSquad.column,
    sortSetUpForSquad.type,
    templateListSquad?.data,
  ]);

  const dataSquadToSetUp = useMemo(() => {
    return {
      data:
        sortedDataForSetUpSquad?.map((l) => ({
          id: l?._id,
          name: capitalizeEachWord(l?.groupName),
          amountPersonil: l?.memberCount,
        })) || [],
      columns: [
        {
          name: "name",
          title: (
            <TabHeader
              text="Nama Regu"
              isSorted
              sort={sortSetUpForSquad}
              setSort={setSortSetUpForSquad}
            />
          ),
          renderData: (row) => <span>{row?.name}</span>,
        },
        {
          name: "amountPersonil",
          title: <TabHeader text="Jumlah Regu" />,
          renderData: (row) => <span>{row?.amountPersonil}</span>,
        },
      ],
    };
  }, [sortSetUpForSquad, sortedDataForSetUpSquad]);

  const [isSuccessUpdateSetup, setIsSuccessUpdateSetup] = useState(false);
  const [isErrorUpdateSetup, setIsErrorUpdateSetup] = useState(false);

  const handleUpdateSquadForSetUp = async () => {
    try {
      setLoadingPost(true);
      const res = await updateSquadForSetUp({
        groupID: isActiveRadio.id,
        memberID: dataSelectedForSetUp.id,
      });
      if (res.status) {
        setIsSuccessUpdateSetup(true);
        navigate(pathname, { replace: true });
        setTimeout(() => {
          onClose();
        }, 5000);
      }
    } catch (error) {
      console.log("Error update setup regu:", error);
      setIsErrorUpdateSetup(true);
    } finally {
      setLoadingPost(false);
    }
  };

  return (
    <>
      <Toast
        isopen={isSuccessUpdateSetup}
        color="green"
        text={
          dataSelectedForSetUp.squad === "-" || !dataSelectedForSetUp.squad
            ? `Berhasil menambahkan ${dataSelectedForSetUp.name} ke regu ${isActiveRadio?.name}`
            : `Berhasil memperbarui ${dataSelectedForSetUp.name} ke regu ${isActiveRadio?.name}`
        }
        onClose={() => setIsSuccessUpdateSetup(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isErrorUpdateSetup}
        color="red"
        text={
          dataSelectedForSetUp.squad === "-" || !dataSelectedForSetUp.squad
            ? `Gagal menambahkan ${dataSelectedForSetUp.name} ke regu ${isActiveRadio?.name}`
            : `Gagal memperbarui ${dataSelectedForSetUp.name} ke regu ${isActiveRadio?.name}`
        }
        onClose={() => setIsErrorUpdateSetup(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isSuccesAddPersonil}
        color="green"
        text={`Berhasil menambahkan ${isCheckedPersonils?.length} personil kedalam lokasi ${nameLocation}`}
        onClose={() => setIsSuccesAddPersonil(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isErrorAddPersonil}
        color="red"
        text={`Gagal menambahkan ${isCheckedPersonils?.length} personil kedalam lokasi ${nameLocation}`}
        onClose={() => setIsErrorAddPersonil(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isSuccesAddSquad}
        color="green"
        text={`Berhasil menambahkan ${
          isCheckedSquad?.length + 1
        } anggota regu kedalam lokasi ${nameLocation}`}
        onClose={() => setIsSuccesAddSquad(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isErrorAddSquad}
        color="red"
        text={`Gagal menambahkan ${
          isCheckedSquad?.length + 1
        } anggota regu kedalam lokasi ${nameLocation}`}
        onClose={() => setIsErrorAddSquad(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isEmptyNameSquad}
        color="red"
        text={"Nama regu belum diisi"}
        onClose={() => setIsEmptyNameSquad(false)}
      />

      <Toast
        isopen={isErrorAlreadyExistSquadName}
        color="red"
        text={`Nama regu '${squadName}' sudah ada`}
        onClose={() => setIsErrorAlreadyExistSquadName(false)}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        {loadingPost && (
          <div
            style={{ position: "absolute", right: "330px", zIndex: "99999" }}
          >
            <Spinner width="50px" />
          </div>
        )}
        <div
          className={Styles.modalAddWorkLocation}
          is_filled={isOpen?.toString()}
          is_transition={isTransition.toString()}
        >
          <div className={Styles.headerModal}>
            <strong>
              {isFromEllipsisMenu ? "Setup " : "Tambah "}
              {tabsActive === 0 ? "Personil" : "Regu"}
            </strong>
            <Icon
              icon={"close-circle"}
              onClick={onClose}
              className={Styles.closeCircle}
            />
          </div>
          <div className={Styles.viewBetweenCol}>
            <div
              className={`${Styles.tableData} ${
                tabsActive === 1 && Styles.hide
              } ${tabsActive === 1 && Styles.addHeightTop} `}
            >
              {tabsActive === 1 && (
                <div className={Styles.inputFieldSticky}>
                  <InputField
                    isLabel
                    isRequired
                    textLabel="Nama Regu"
                    placeholder="Masukkan nama Regu"
                    setValue={setSquadName}
                    value={squadName}
                  />
                </div>
              )}

              <div className={Styles.wrapTable}>
                {!isFromEllipsisMenu ? (
                  <label className={Styles.labelSquad} htmlFor="">
                    Anggota Regu <span>*</span>
                  </label>
                ) : (
                  <div className={Styles.personilFilled}>
                    <label>Nama Personil</label>
                    <div className={Styles.namesSelected}>
                      <img
                        src={
                          dataSelectedForSetUp?.profilePicture === "" ||
                          dataSelectedForSetUp?.profilePicture === null
                            ? AVA_DEFAULT
                            : dataSelectedForSetUp?.profilePicture
                        }
                        alt="profile"
                      />
                      <div>
                        <h5>{dataSelectedForSetUp.name}</h5>
                        <span>{dataSelectedForSetUp.level}</span>
                      </div>
                    </div>
                  </div>
                )}
                {isFromEllipsisMenu && (
                  <label
                    className={Styles.labelSquad}
                    style={{ marginTop: "12px" }}
                  >
                    Pilih Regu <span>*</span>
                  </label>
                )}
                <div className={Styles.withBorder}>
                  <div className={Styles.overfolowContain}>
                    {!isFromEllipsisMenu && (
                      <div className={`${Styles.tabs} ${Styles.sticky}`}>
                        {listSquadFilterTabs.map((el, idx) => (
                          <span
                            className={`${
                              (tabsActive === 0
                                ? activeListPersonilFilterTabs
                                : activeListSquadFilterTabs) === idx &&
                              Styles.active
                            }`}
                            key={idx}
                            onClick={() =>
                              tabsActive === 0
                                ? setactiveListPersonilFilterTabs(idx)
                                : setIsActiveListSquadFilterTabs(idx)
                            }
                          >
                            {el}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className={Styles.wrapMainContent}>
                      {!isFromEllipsisMenu && (
                        <div className={Styles.sticky}>
                          <SearchBar
                            setValue={
                              tabsActive === 1
                                ? setSearchSquad
                                : setSearchPersonil
                            }
                            search={
                              tabsActive === 1 ? searchSquad : searchPersonil
                            }
                            placeholder={
                              tabsActive === 0
                                ? activeListPersonilFilterTabs === 0
                                  ? "Cari Personil"
                                  : "Cari Danru"
                                : activeListSquadFilterTabs === 0
                                ? "Cari Personil"
                                : "Cari Danru"
                            }
                          />
                        </div>
                      )}

                      <div
                        className={Styles.scrollContain}
                        ref={
                          tabsActive === 0
                            ? listInnerRefPersonil
                            : listInnerRefSquad
                        }
                        onScroll={
                          tabsActive === 0 ? onScrollPersonil : onScrollSquad
                        }
                        style={{
                          height: isFromEllipsisMenu
                            ? `calc(${height}px - 280px)`
                            : tabsActive === 0
                            ? `calc(${height}px - 290px)`
                            : `calc(${height}px - 375px)`,
                          overflowY: "auto",
                        }}
                      >
                        <div>
                          {/* {(loadingGetPersonil || loadingGetSquad) && (
                            <div
                              style={{
                                position: "fixed",
                                top: "40%",
                                right: "300px",
                                zIndex: "9999",
                              }}
                            >
                              <Spinner width="30px" />
                            </div>
                          )} */}

                          {isFromEllipsisMenu ? (
                            <div>
                              <Table
                                data={dataSquadToSetUp.data}
                                columns={dataSquadToSetUp.columns}
                                withCheckbox
                                isWithRadioButton
                                bgHeader="white"
                                isBorderBottomTableHead
                                classNameForTableHead={`${Styles.customStickyToTableHead}`}
                                isTableHeadSticky
                                isActiveRadio={isActiveRadio}
                                setIsActiveRadio={setIsActiveRadio}
                                activeRadioFilledName={isActiveRadio?.squad}
                              />
                            </div>
                          ) : (
                            <Table
                              data={dataListPersonil.data}
                              columns={dataListPersonil.columns}
                              withCheckbox
                              isWithRadioButton={
                                tabsActive === 1 &&
                                activeListSquadFilterTabs === 1
                              }
                              bgHeader="white"
                              isBorderBottomTableHead
                              classNameForTableHead={`${
                                Styles.customStickyToTableHead
                              } ${tabsActive === 1 && Styles.addTopSticky}`}
                              isTableHeadSticky
                              isChecked={
                                tabsActive === 0
                                  ? isCheckedPersonils
                                  : activeListSquadFilterTabs === 1
                                  ? isCheckedSquadDanru.length === 1
                                    ? isCheckedSquadDanru
                                    : isCheckedSquadDanru.length > 1
                                    ? isCheckedSquadDanru.shift()
                                    : isCheckedSquadDanru
                                  : isCheckedSquad
                              }
                              setIsChecked={
                                tabsActive === 0
                                  ? setIsCheckedPersonils
                                  : activeListSquadFilterTabs === 1
                                  ? setIsCheckedSquadDanru
                                  : setIsCheckedSquad
                              }
                              isActiveRadio={isActiveRadio}
                              setIsActiveRadio={setIsActiveRadio}
                              isLoading={
                                activeListSquadFilterTabs === 0
                                  ? loadingGetPersonil
                                  : activeListSquadFilterTabs === 1
                                  ? loadingGetSquad
                                  : false
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.footerModal}>
              {isFromEllipsisMenu ? (
                <Button
                  text="Simpan"
                  onClick={() => handleUpdateSquadForSetUp()}
                  isLoadingIndicator={loadingPost}
                  isDisabled={!isActiveRadio?.name}
                />
              ) : tabsActive === 1 ? (
                <Button
                  text="Simpan"
                  isDisabled={
                    isCheckedSquad.length === 0 || !isActiveRadio || !squadName
                  }
                  onClick={() => handleSaveSquad()}
                  isLoadingIndicator={loadingPost}
                />
              ) : (
                <Button
                  text="Simpan"
                  onClick={() => handleSavePersonil()}
                  isDisabled={isCheckedPersonils.length === 0}
                  isLoadingIndicator={loadingPost}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
