import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./Style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopBar from "@Atom/TopBar";
import useWindowSize from "@Hooks/useWindowSize";
import Tooltip from "@Atom/Tooltip";
import { makeRandomString } from "helpers/makeRandomString";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import useOutsideClick from "@Hooks/useOutsideClick";
import { SideBarStates } from "contexts/SideBarStates";

export default function SideBar({ children, logo }) {
  const { pathname, search } = useLocation();
  const locationName = capitalizeEachWord(
    sessionStorage.getItem("locationName")
  );
  const guardPostName = sessionStorage.getItem("guardPostName");
  const patrolPointName = sessionStorage.getItem("patrolPointName");

  const navigate = useNavigate();
  const { id, guardPostID, patrolPointID, month } = useParams();
  const [breadcrumbsSPA, setBreadcrumbsSPA] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenShortBreadcrumbs, setIsOpenShortBreadcrumbs] = useState(false);
  const sideItems = useMemo(() => {
    return [
      // {
      //   icon: "element-3",
      //   title: "Dashboard",
      //   path: "",
      // },
      {
        icon: "buildings-2",
        path: "detail-client",
        title: "Detail Klien",
      },
      {
        icon: "location-fill",
        path: "work-location",
        title: "Lokasi Kerja",
        subMenu: [
          {
            title: "Informasi Lokasi",
            path: `work-location/detail/${id}/information`,
            icon: "building-3",
          },
          {
            title: "Pos Jaga",
            path: `work-location/detail/${id}/guard-post`,
            icon: "location-tick",
          },
          {
            title: "Titik Patroli",
            path: `work-location/detail/${id}/patrol-point`,
            icon: "location-tick",
          },
          {
            title: "Daftar Personil & Regu",
            path: `work-location/detail/${id}/list-personil-and-squad`,
            icon: "people",
          },

          {
            title: "Jadwal Kerja",
            path: `work-location/detail/${id}/work-schedule`,
            icon: "calendar",
          },
        ],
      },
      {
        icon: "people",
        path: "list-members",
        title: "Daftar Personil",
      },
      {
        icon: "calendar",
        title: "Absensi",
        path: "attendance",
      },
      {
        icon: "activity",
        title: "Laporan",
        path: "report",
        subMenu: [
          {
            title: "Laporan Aktifitas",
            path: "report",
            icon: "security-man",
          },
          {
            title: "Laporan Patroli",
            path: "report/patrol",
            icon: "report-alert",
          },
          {
            title: "Laporan Kejadian",
            path: "report/incident",
            icon: "Information",
          },
          // {
          //   title: "Laporan SOS",
          //   path: "report/sos",
          //   icon: "sos",
          // },
        ],
      },
      // {
      //   icon: "add-item",
      //   title: "Pengelolaan Konten",
      //   path: "content-manage",
      // },
      // {
      //   icon: "receipt-2",
      //   title: "Slip Gaji",
      //   path: "salary",
      // },
      // {
      //   icon: "heart-add",
      //   title: "Benefit Karyawan",
      //   path: "employee-benefit",
      // },
      {
        icon: "megaphone-filled",
        path: "announcements",
        title: "Pengumuman",
      },
      {
        id: makeRandomString(5),
        icon: "setting-2",
        title: "Pengaturan",
        path: "settings",
        subMenu: [
          // {
          //   title: "Pengaturan Perusahaan",
          //   path: "settings/company",
          //   icon: "building",
          // },
          {
            title: "Skema Absensi",
            path: "settings/schema",
            icon: "calendar",
          },
        ],
      },
    ];
  }, [id]);

  const sideActive = useMemo(() => {
    return sideItems.findIndex((val) => val.path === pathname.split("/")[1]);
  }, [pathname, sideItems]);

  const handleCloseExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded) {
      sessionStorage.removeItem("sideBarActive");
    }
  }, [isExpanded]);

  useEffect(() => {
    if (sideActive) {
      setIsExpanded(true);
    }
  }, [sideActive]);
  const [isOpacity, setIsOpacity] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpacity(true);
    }, 400);
    setIsOpacity(false);
  }, [isExpanded]);

  const [isActiveSubMenu, setIsActiveSubMenu] = useState(
    +sessionStorage.getItem("activeSubMenu") || 0
  );

  const handleActiveSubMenu = (e) => {
    setIsActiveSubMenu(e);
  };

  useEffect(() => {
    sessionStorage.setItem("activeSubMenu", isActiveSubMenu);
  }, [isActiveSubMenu]);

  const { width } = useWindowSize();

  // MOBILE
  const [isActiveSubMenuMobile, setIsActiveSubMenuMobile] = useState(0);

  const handleActiveSubMenuMobile = (e) => {
    setIsActiveSubMenuMobile(e);
  };
  const [isOpenSideBarMobile, setIsOpenSideBarMobile] = useState(false);
  const handleOpenSideMobile = useCallback(() => {
    setIsOpenSideBarMobile(!isOpenSideBarMobile);
  }, [isOpenSideBarMobile]);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState();
  const [isCloseSubMenu, setIsCloseSubMenu] = useState();
  const handleCloseSubMenu = (idx) => {
    setIsOpenSubMenu(idx);
    if (isOpenSubMenu === idx) {
      setIsOpenSubMenu(!idx);
      setIsCloseSubMenu(!isCloseSubMenu);
    }
  };

  const breadcrumbsPath = useMemo(() => {
    let breadcrumbs = [];
    switch (pathname) {
      case `/work-location/detail/${id}/information`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Informasi Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
        ];
        break;
      case `/work-location/detail/${id}/guard-post`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          { label: "Pos Jaga", path: `/work-location/detail/${id}/guard-post` },
        ];
        break;
      case `/work-location/detail/${id}/guard-post/${guardPostID}`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          { label: "Pos Jaga", path: `/work-location/detail/${id}/guard-post` },
          {
            label: guardPostName,
            path: `/work-location/detail/${id}/guard-post/${guardPostID}`,
          },
        ];
        break;
      case `/work-location/detail/${id}/patrol-point`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Titik Patroli",
            path: `/work-location/detail/${id}/patrol-point`,
          },
        ];
        break;
      case `/work-location/detail/${id}/patrol-point/${patrolPointID}`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Titik Patroli",
            path: `/work-location/detail/${id}/patrol-point`,
          },
          {
            label: patrolPointName,
            path: `/work-location/detail/${id}/patrol-point/${patrolPointID}`,
          },
        ];
        break;
      case `/work-location/detail/${id}/list-members`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Daftar Personil & Regu",
            path: `/work-location/detail/${id}/list-members`,
          },
        ];
        break;
      case `/work-location/detail/${id}/work-schedule`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Jadwal Kerja",
            path: `/work-location/detail/${id}/work-schedule`,
          },
        ];
        break;
      case `/work-location/detail/${id}/work-schedule/detail/${month}`:
        breadcrumbs = [
          { label: "Lokasi Kerja", path: "/work-location" },
          {
            label: "Detail Lokasi",
            path: `/work-location/detail/${id}/information`,
          },
          {
            label: "Jadwal Kerja",
            path: `/work-location/detail/${id}/work-schedule`,
          },
          {
            label: "Pengaturan Jadwal Kerja",
            path: `/work-location/detail/${id}/work-schedule/detail/${month}`,
          },
        ];
        break;
      case `/report/activity/detail/` + id:
        breadcrumbs = [
          { label: "Laporan", path: "/report/activity" },
          { label: "Laporan Aktifitas", path: `/report/activity` },
          {
            label: locationName,
            path: `/report/activity/detail/${id}`,
          },
        ];
        break;

      case `/report/patrol/detail/` + id:
        breadcrumbs = [
          { label: "Laporan", path: "/report/patrol" },
          {
            label: "Laporan Patroli",
            path: `/report/patrol`,
          },
          {
            label: locationName,
            path: `/report/patrol/detail/${id}`,
          },
        ];
        break;
      case `/report/incident/detail/` + id:
        breadcrumbs = [
          { label: "Laporan", path: `/report/incident` },
          {
            label: "Laporan Kejadian",
            path: `/report/incident`,
          },
          {
            label: locationName,
            path: `/report/incident/detail/${id}`,
          },
        ];
        break;
      case `/report/sos/detail/` + id:
        breadcrumbs = [
          { label: "Laporan", path: `/report/sos` },
          {
            label: "Laporan SOS",
            path: `/report/sos`,
          },
          {
            label: locationName,
            path: `/report/sos/detail/${id}`,
          },
        ];
        break;
      case `/announcements`:
        if (breadcrumbsSPA) {
          breadcrumbs = [
            { label: "Pengumuman", path: `/announcements` },
            { label: "Detail Pengumuman", path: `/announcements${search}` },
          ];
        }
        break;
      default:
        breadcrumbs = [{ label: "", path: "" }];
    }
    return breadcrumbs;
  }, [
    guardPostID,
    guardPostName,
    id,
    locationName,
    month,
    pathname,
    patrolPointID,
    patrolPointName,
    search,
    breadcrumbsSPA,
  ]);

  const [isHoverIcon, setIsHoverIcon] = useState(false);

  const [activeGrandChild, setActiveGrandChild] = useState(
    +sessionStorage.getItem("activeGrandChild") || 0
  );
  const [isOpenGrandChild, setIsOpenGrandChild] = useState(
    +sessionStorage.getItem("isOpenGrandChild")
  );
  useEffect(() => {
    sessionStorage.setItem("isOpenGrandChild", isOpenGrandChild);
  }, [isOpenGrandChild]);

  useEffect(() => {
    sessionStorage.setItem("activeGrandChild", activeGrandChild);
  }, [activeGrandChild]);

  const handleOpendGrandChild = (idx) => {
    if (idx === isOpenGrandChild) {
      setIsOpenGrandChild(!idx);
    } else {
      const isHaveGrandChild =
        sideItems[sideActive]?.subMenu?.length >= 1 &&
        sideItems[sideActive]?.subMenu[isActiveSubMenu]?.grandChild?.length >=
          1;
      if (isHaveGrandChild) {
        setIsOpenGrandChild(idx);
      }
    }
  };

  useEffect(() => {
    setActiveGrandChild(0);
    if (
      sideItems[sideActive]?.subMenu?.length >= 1 &&
      sideItems[sideActive]?.subMenu[isActiveSubMenu]?.grandChild?.length >= 1
    ) {
      navigate(sideItems[sideActive]?.subMenu[isActiveSubMenu]?.path);
    }
  }, [isActiveSubMenu, navigate, sideActive, sideItems]);

  useEffect(() => {
    sessionStorage.setItem("sideBarActive", isExpanded);
  }, [isExpanded]);

  // active submenu in detail
  useEffect(() => {
    if (pathname === "/work-location" + search) {
      setIsActiveSubMenu(0);
    }
  }, [pathname, search]);

  let currentActivePage = "";

  if (pathname.startsWith("/report")) {
    currentActivePage = "Laporan";
  } else if (pathname.startsWith("/work-location/detail")) {
    currentActivePage = "Detail Lokasi";
  } else if (pathname.startsWith("/list-members")) {
    currentActivePage = "Daftar Personil";
  } else if (pathname.startsWith("/settings")) {
    currentActivePage = "Pengaturan";
  } else {
    currentActivePage = "";
  }

  const sideBarToShow = () => {
    if (width >= 768) {
      if (
        sideItems[sideActive]?.subMenu &&
        pathname.startsWith("/work-location/detail")
      ) {
        return true;
      } else if (
        sideItems[sideActive]?.subMenu &&
        !pathname.startsWith("/work-location")
      ) {
        return true;
      }
      return false;
    }
  };

  const refDropdownBreadcrumbs = useRef();
  const outsideClickBreadcrumbs = useOutsideClick(refDropdownBreadcrumbs);

  useEffect(() => {
    if (isOpenShortBreadcrumbs && outsideClickBreadcrumbs) {
      setIsOpenShortBreadcrumbs(false);
    }
  }, [isOpenShortBreadcrumbs, outsideClickBreadcrumbs]);

  return (
    <div className={Styles.containerSideBarMobile}>
      <TopBar onClick={handleOpenSideMobile} />
      {width >= 768 && (
        <section className={Styles.wrapperSideBar}>
          <div className={Styles.mainContain}>
            <div className={Styles.wrapperMenus}>
              <div className={Styles.logo}>
                <img src={logo || Images.MWS_SECURITY_LOGO} alt="logo" />
              </div>
              {sideItems?.slice(0, -1)?.map((item, idx) => (
                <div
                  key={`list-menu-${idx}`}
                  className={Styles.iconMenu}
                  // is-active={
                  //   item?.path === "" || pathname === "/"
                  //     ? item?.path === "" && pathname === "/"
                  //       ? "true"
                  //       : "false"
                  //     : sideActive === idx
                  //     ? "true"
                  //     : "false"
                  // }
                  is-active={
                    item?.path === "" || pathname === "/"
                      ? item?.path === "" && pathname === "/"
                        ? "true"
                        : "false"
                      : pathname?.includes(item?.path)
                      ? "true"
                      : "false"
                  }
                >
                  <Tooltip
                    onMouseEnter={
                      sideActive === idx ? () => {} : () => setIsHoverIcon(idx)
                    }
                    onMouseLeave={
                      sideActive === idx
                        ? () => {}
                        : () => setIsHoverIcon(false)
                    }
                    text={sideItems[isHoverIcon]?.title}
                    isActive={sideActive === idx}
                  >
                    <Icon
                      icon={item.icon}
                      size={"24"}
                      // color={
                      //   isHoverIcon === idx
                      //     ? "#2C5364"
                      //     : (sideActive === idx && item?.path !== "") ||
                      //       (pathname === "/" && item?.path === "")
                      //     ? "#2C5364"
                      //     : "#A9B3C1"
                      // }
                      color={
                        (pathname?.includes(item?.path) && item?.path !== "") ||
                        (pathname === "/" && item?.path === "")
                          ? "#2C5364"
                          : "#A9B3C1"
                      }
                      style={{ transition: "0.3s" }}
                      className={Styles.icon}
                      onClick={() => {
                        navigate(item?.path);
                        setIsActiveSubMenu(0);
                      }}
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
            {sideItems?.slice(sideItems.length - 1)?.map((item, idx) => (
              <div key={`last-list-menu-${item?.id}`}>
                <div
                  className={Styles.iconMenu}
                  is-active={
                    item?.path === "" || pathname === "/"
                      ? item?.path === "" && pathname === "/"
                        ? "true"
                        : "false"
                      : pathname?.includes(item?.path)
                      ? "true"
                      : "false"
                  }
                >
                  <Tooltip
                    onMouseEnter={
                      sideActive === item?.id
                        ? () => {}
                        : () => setIsHoverIcon(item?.id)
                    }
                    onMouseLeave={
                      sideActive === item?.id
                        ? () => {}
                        : () => setIsHoverIcon(false)
                    }
                    text={item.title}
                    isActive={sideActive === item?.id}
                  >
                    <Icon
                      icon={item.icon}
                      size={"24"}
                      color={
                        isHoverIcon === item.id
                          ? "#2C5364"
                          : (pathname?.includes(item?.path) &&
                              item?.path !== "") ||
                            (pathname === "/" && item?.path === "")
                          ? "#2C5364"
                          : "#A9B3C1"
                      }
                      style={{ transition: "0.3s" }}
                      className={Styles.icon}
                      onClick={() => {
                        navigate(item?.path);
                        setIsActiveSubMenu(0);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
      {width <= 768 && ( // mobileView
        <div className={Styles.overFlowSideMobile}>
          <section
            className={Styles.sideBarMobile}
            is-open={isOpenSideBarMobile?.toString()}
          >
            {isOpenSideBarMobile && (
              <div
                className={Styles.wrapperMenuMobile}
                is-opacity={isOpacity?.toString()}
              >
                <div className={Styles.headerMenu}>
                  <h2>Menu</h2>
                  <Icon
                    icon={"close-circle"}
                    className={Styles.iconCloseSideMobile}
                    onClick={handleOpenSideMobile}
                  />
                </div>
                <div className={Styles.listMenuMobile}>
                  {sideItems?.map((item, idx) => (
                    <Fragment key={idx}>
                      <div
                        className={Styles.between}
                        is-active={sideActive === idx ? "true" : "false"}
                        onClick={() => {
                          // handleIconClick(idx);
                          handleCloseSubMenu(idx);
                          navigate(item?.path);
                        }}
                      >
                        <div>
                          <Icon
                            icon={item.icon}
                            className={Styles.iconMenuSideMobile}
                          />
                          <span>{item.title}</span>
                        </div>
                        {item.subMenu && (
                          <Icon
                            icon={"arrow-down-side"}
                            className={Styles.iconMenuSideMobileArrow}
                            onClick={() => handleCloseSubMenu(idx)}
                            is-close={isOpenSubMenu === idx ? "true" : "false"}
                            is-active-sub={(
                              sideActive === idx && isOpenSubMenu === false
                            ).toString()}
                          />
                        )}
                      </div>
                      {item.subMenu && isOpenSubMenu === idx && (
                        <div className={Styles.wrapperSubMenu}>
                          <div className={Styles.separatorLeft}></div>
                          <div className={Styles.subMenu}>
                            {item?.subMenu?.map((sub, idx) => (
                              <div
                                onClick={() => {
                                  handleActiveSubMenuMobile(idx);
                                  navigate(sub.path);
                                }}
                                key={idx}
                                is-active-sub-menu={(
                                  isActiveSubMenuMobile === idx
                                )?.toString()}
                              >
                                <span>{sub.title}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          </section>
        </div>
      )}
      {sideBarToShow() ? (
        <section>
          <div
            className={Styles.expanded}
            is-expanded={isExpanded?.toString()}
            style={
              width < 768 ||
              pathname.includes("add-employee") ||
              pathname.includes("organization")
                ? { position: "absolute" }
                : { undefined }
            }
          >
            <div
              className={Styles.closeExpanded}
              onClick={handleCloseExpand}
              is-expanded={isExpanded?.toString()}
            >
              <Icon
                icon={"arrow-down"}
                className={Styles.iconCloseExpanded}
                is-expanded={isExpanded?.toString()}
              />
            </div>

            {isExpanded && (
              <section
                className={Styles.listMenus}
                is-opacity={isOpacity?.toString()}
              >
                <h2>{currentActivePage}</h2>
                <div className={Styles.wrapperMenus}>
                  {sideItems.map(
                    (item, idx) =>
                      sideActive === idx &&
                      item?.subMenu?.map((sub, i) => (
                        <div className={Styles.viewerSubMenus} key={i}>
                          <div
                            onClick={() => {
                              handleActiveSubMenu(i);
                              isActiveSubMenu !== i && navigate(sub?.path);
                            }}
                            is-active={
                              isActiveSubMenu === i ||
                              isActiveSubMenu === sub.title
                                ? "true"
                                : "false"
                            }
                          >
                            <Icon
                              icon={sub.icon}
                              size={"20"}
                              className={Styles.icon}
                            />
                            <span onClick={() => navigate(sub?.path)}>
                              {sub.title}
                            </span>
                            {sub?.grandChild && (
                              <Icon
                                icon={"arrow-down-side"}
                                color={
                                  isActiveSubMenu === i ||
                                  isActiveSubMenu === sub.title
                                    ? "white"
                                    : "#556377"
                                }
                                size={"20"}
                                className={Styles.grandChild}
                                onClick={() => handleOpendGrandChild(i)}
                                style={{
                                  transition: "0.2s",
                                  transform:
                                    isOpenGrandChild === i &&
                                    (isActiveSubMenu === i ||
                                      isActiveSubMenu === sub.title)
                                      ? "rotate(180deg)"
                                      : "",
                                }}
                              />
                            )}
                          </div>
                          {sub?.grandChild &&
                            isOpenGrandChild === i &&
                            (isActiveSubMenu === i ||
                              isActiveSubMenu === sub.title) && (
                              <div className={Styles.wrapperGrandChild}>
                                <div
                                  className={Styles.divider}
                                  style={{
                                    height: `${sub?.grandChild?.length * 35}px`,
                                  }}
                                ></div>
                                <div className={Styles.wrapperMenu}>
                                  {sub.grandChild.map((g, ig) => (
                                    <div
                                      className={Styles.menus}
                                      is-active={(
                                        activeGrandChild === ig
                                      )?.toString()}
                                      key={ig}
                                      onClick={() => {
                                        setActiveGrandChild(ig);
                                        navigate(g?.path);
                                      }}
                                    >
                                      <span>{g?.title}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                        </div>
                      ))
                  )}
                </div>
              </section>
            )}
          </div>
        </section>
      ) : null}
      <div className={Styles.contentWrapper}>
        {width > 1024 && (
          <div className={Styles.wrapperBreadcrumbs}>
            <div
              is-absolute-sidebar={(
                isExpanded && pathname.includes("organization")
              )?.toString()}
            >
              {breadcrumbsPath.map((item, idx) => (
                <Fragment key={idx}>
                  <div
                    onClick={() =>
                      breadcrumbsPath.length > 3 && idx === 1
                        ? setIsOpenShortBreadcrumbs(!isOpenShortBreadcrumbs)
                        : `${
                            (navigate(item.path),
                            setIsActiveSubMenu(
                              pathname.startsWith(`/work-location/detail/`)
                                ? idx - 1
                                : idx
                            ))
                          }`
                    }
                    className={Styles.text}
                    is-active={(breadcrumbsPath.length - 1 <= idx)?.toString()}
                  >
                    {breadcrumbsPath.length > 3 && idx === 1 ? (
                      <span className={Styles.dots}>
                        <span>...</span>
                        <Icon
                          icon={"arrow-down"}
                          className={Styles.arrowDots}
                          size={16}
                          isopen={isOpenShortBreadcrumbs.toString()}
                          color={"#a9b3c1"}
                        />

                        {isOpenShortBreadcrumbs && (
                          <div
                            className={Styles.menuDropdownBredcrumbs}
                            ref={refDropdownBreadcrumbs}
                          >
                            <ul>
                              {breadcrumbsPath
                                .slice(1, breadcrumbsPath.length - 1)
                                .map((hiddenItem, hiddenIdx) => (
                                  <li
                                    key={hiddenIdx}
                                    onClick={() => {
                                      navigate(hiddenItem.path);
                                      setIsActiveSubMenu(
                                        pathname.startsWith(
                                          "/work-location/detail/"
                                        ) && hiddenIdx === 0
                                          ? 0
                                          : hiddenItem.label
                                      );
                                    }}
                                  >
                                    {hiddenItem.label}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </span>
                    ) : breadcrumbsPath.length > 3 ? (
                      (idx === breadcrumbsPath.length - 1 || idx === 0) &&
                      item.label
                    ) : (
                      item.label
                    )}
                  </div>
                  {breadcrumbsPath.length > 3
                    ? breadcrumbsPath.length > 3 &&
                      idx !== 2 &&
                      idx !== breadcrumbsPath.length - 1 && (
                        <Icon
                          icon={"arrow-down"}
                          color={"#3E4856"}
                          size={16}
                          style={{
                            transform: "rotate(-90deg)",
                            cursor: "pointer",
                          }}
                          className={Styles.iconArrowRight}
                        />
                      )
                    : idx !== breadcrumbsPath.length - 1 && (
                        <Icon
                          icon={"arrow-down"}
                          color={"#3E4856"}
                          size={16}
                          style={{
                            transform: "rotate(-90deg)",
                            cursor: "pointer",
                          }}
                          className={Styles.iconArrowRight}
                        />
                      )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        <SideBarStates.Provider
          value={{ isOpenSideBar: isExpanded, setBreadcrumbsSPA }}
        >
          {children}
        </SideBarStates.Provider>
      </div>
    </div>
  );
}
