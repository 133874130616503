import React, { useState } from "react";
import Styles from "./style.module.scss";
import DatePicker from "@Atom/DatePicker";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { downloadExcel } from "@Organism/Report/downloadReport";
import { useParams } from "react-router-dom";
// import useOutsideClick from "@Hooks/useOutsideClick";
export default function ButtonDownloadReport({
  type,
  month,
  year,
  date,
  ref,
  shift,
}) {
  const listText = ["Harian", "Bulanan", "Custom Date"];
  const [optionModal, setOptionModal] = useState("Harian");
  const [inputStartDate, setinputStartDate] = useState("");
  const [inputEendDate, setInputEndDate] = useState("");
  const { id: locationID } = useParams();

  //   const refBox = useRef(null);
  //   const isOutsideClick = useOutsideClick(refBox);

  //   useEffect(() => {
  //     if (isOutsideClick && !!optionModal) {
  //       setOptionModal(false);
  //     }
  //   }, [isOutsideClick, optionModal]);

  return (
    <>
      {optionModal === listText[2] ? (
        <div className={Styles.datesWrapper} ref={ref}>
          <div className={Styles.header}>
            <div>
              <Icon icon={"document-upload"} color={"#2C5364"} size={"20"} />
              <strong>Pilih Tanggal</strong>
            </div>
            <Icon
              icon={"close-circle"}
              color={"#3E4856"}
              className={Styles.closeIcon}
              size={"14"}
              onClick={() => setOptionModal(false)}
            />
          </div>
          <div className={Styles.inputField}>
            <DatePicker
              placeholder="Tanggal Awal"
              setValue={setinputStartDate}
              value={inputStartDate}
            />
            <DatePicker
              placeholder="Tanggal Akhir"
              setValue={setInputEndDate}
              value={inputEendDate}
            />
          </div>
          <div className={Styles.btnDownload}>
            <Button
              text="Download"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() =>
                downloadExcel({
                  type,
                  startDate: inputStartDate,
                  endDate: inputEendDate,
                  locationID: locationID,
                  downloadType: optionModal,
                  shift,
                })
              }
            />
          </div>
        </div>
      ) : (
        <div className={Styles.wrapperBtnDownloadReport} ref={ref}>
          <ul>
            {listText.map((l, idx) => (
              <li
                onClick={() =>
                  idx !== 2
                    ? downloadExcel({
                        type,
                        month,
                        year,
                        date,
                        locationID: locationID,
                        downloadType: optionModal,
                        shift,
                      })
                    : setOptionModal(l)
                }
                key={idx}
              >
                {l}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
