import React, { Fragment } from "react";
import Styles from "./style.module.scss";
import Skeleton from "@Atom/Skeleton";
export default function SkeletonDetailClientPage() {
  return (
    <div className={Styles.wrapperSkeletonDetailClient}>
      <Skeleton isV2 h="20px" w="15%" />
      <div className={Styles.card}>
        <div>
          <Skeleton isV2 h="40px" w="60%" />
        </div>
        <div>
          <Skeleton isV2 w="30%" />
          <Skeleton isV2 w="20%" />
        </div>
        <div>
          <Skeleton isV2 w="25%" />
          <Skeleton isV2 w="40%" />
        </div>
        <div>
          <Skeleton isV2 w="30%" />
          <Skeleton isV2 w="25%" />
        </div>
        <div>
          <Skeleton isV2 w="25%" />
          <Skeleton isV2 w="25%" />
        </div>
        <div>
          <Skeleton isV2 w="30%" />
          <Skeleton isV2 w="20%" />
        </div>
        <div>
          <Skeleton isV2 w="35%" />
          <Skeleton isV2 w="45%" />
        </div>
        <div>
          <Skeleton isV2 w="20%" />
          <Skeleton isV2 w="25%" />
        </div>
      </div>
      <div className={Styles.viewRow}>
        <div className={Styles.card}>
          {Array.from({ length: 8 }).map((_, idx) => (
            <Fragment key={idx}>
              <Skeleton isV2 />
            </Fragment>
          ))}
        </div>
        <div className={Styles.card}>
          {Array.from({ length: 8 }).map((_, idx) => (
            <Fragment key={idx}>
              <Skeleton isV2 />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
