import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import HamburgerMenu from "@Assets/Icon/hamburgerMenu.svg";
import Icon from "@Atom/Icon";
import AvatarImage from "@Assets/Images/ava-default.png";
import TabHeader from "@Atom/TabHeader";
import { getAllClient } from "@Services/clients/getAllClient";
import { useDebounce } from "@Hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import Toast from "@Atom/Toast";
import ModalTableClient from "@Molecule/_modal/TableClient";
import useOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";

function TopBar({ onClick = () => {} }) {
  const navigate = useNavigate();
  const [isOpenModalClient, setIsOpenModalClient] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isSavedClient, setIsSavedClient] = useState(false);
  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("currentClient")) || ""
  );

  const [isSuccessChangeClient, setIsSuccessChangeClient] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const debounceQuery = useDebounce(searchQuery, 500);

  const handleSaveClient = () => {
    setIsSavedClient(true);
    if (client) {
      setTimeout(() => {
        localStorage.setItem("currentClient", JSON.stringify(client));
        setIsSuccessChangeClient(true);
      }, 1500);
    }
  };

  setTimeout(() => {
    if (isSavedClient) {
      setIsSavedClient(false);
      navigate("/detail-client", { replace: true });
      setIsOpenModalClient(false);
      setIsSuccessChangeClient(false);
      window.location.reload();
    }
  }, 2200);

  useEffect(() => {
    if (
      client?.name !==
      JSON.parse(localStorage.getItem("currentClient"))?.name?.toString()
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [client, isSavedClient]);

  const [dataClients, setDataClients] = useState([]);

  const fetchAllClient = useCallback(async () => {
    try {
      const { data } = await getAllClient(page, limit, debounceQuery);
      setDataClients(data);
    } catch (error) {
      console.log("error get data client: ", error);
    }
  }, [page, limit, debounceQuery]);

  useEffect(() => {
    fetchAllClient();
  }, [page, limit, fetchAllClient]);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);
  const username = localStorage?.getItem("username") || "-";
  const role = localStorage?.getItem("role") || "-";

  const templateDataClients = useMemo(() => {
    return {
      data: dataClients?.data?.map((c) => ({
        id: c?._id,
        name: c?.name,
        code: c?.code,
        location: "-",
      })),
      totalData: dataClients?.totalData,
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama Klien" isSorted />,
          renderData: (row) => (
            <div
              onClick={() => {
                setClient(row);
              }}
              style={{ cursor: "pointer" }}
            >
              <span>{row.name}</span>
            </div>
          ),
        },
        {
          name: "location",
          title: <TabHeader text="Lokasi" isSorted />,
          renderData: (row) => <span>{row.location}</span>,
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <>
              <div style={{ opacity: client?.name === row.name ? "1" : "0" }}>
                <Icon icon={"check"} color={"#2C5364"} size={"24"} />
              </div>
            </>
          ),
        },
      ],
    };
  }, [client, dataClients?.data, dataClients?.totalData]);

  const [expandProfile, setExpandProfile] = useState(false);
  const boxReffPofile = useRef(null);

  const outsideClick = useOutsideClick(boxReffPofile);

  useEffect(() => {
    if (outsideClick && expandProfile) {
      setExpandProfile(false);
    }
  }, [expandProfile, outsideClick]);

  return (
    <div>
      <Toast
        isopen={isSuccessChangeClient}
        color="green"
        text="Berhasil mengubah klien"
        onClose={() => setIsSuccessChangeClient(false)}
      />

      <ModalTableClient
        isOpenModalClient={isOpenModalClient}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        client={client}
        dataTable={templateDataClients}
        handleSaveClient={handleSaveClient}
        isDisabledBtnSave={isDisabledBtn}
        isSavedClient={isSavedClient}
        setIsOpenModalClient={setIsOpenModalClient}
        isSuccessChangeClient={isSuccessChangeClient}
      />

      <div className={Styles.warpperTopBar}>
        <div className={Styles.topBarBetween}>
          <div className={Styles.hamburgerMenu} onClick={onClick}>
            <img src={HamburgerMenu} alt="icon-menu" />
          </div>
          <div className={Styles.wrapperIconTopBar}>
            <div className={Styles.dropdownChangeClient}>
              <div className={Styles.select}>
                <div>
                  <Icon
                    icon={"building"}
                    color={"#2C5364"}
                    size={"16"}
                    className={Styles.logo}
                    style={{ flexShrink: "0" }}
                  />
                  <span>
                    {JSON.parse(localStorage.getItem("currentClient"))?.name}
                  </span>
                </div>
              </div>
              <div
                className={Styles.changeClient}
                onClick={() => setIsOpenModalClient(!isOpenModalClient)}
              >
                <Icon
                  icon={"edit-2"}
                  size={"16"}
                  className={Styles.edit}
                  color={"#2C5364"}
                />
              </div>
            </div>

            {/* <div> HIDDEN-SECTION-MENU
              <Icon icon={"menu"} className={Styles.iconTopBar} />
            </div>
            <div>
              <Icon icon={"notification"} className={Styles.iconTopBar} />
            </div> */}
            <div
              className={Styles.profileTopBar}
              onClick={() => setExpandProfile(!expandProfile)}
            >
              <div>
                <img
                  src={AvatarImage}
                  alt="profile"
                  height={"36px"}
                  width={"36px"}
                />
              </div>
              <div>
                <Icon
                  icon={"arrow-down"}
                  is_open={expandProfile.toString()}
                  className={Styles.iconArrowProfile}
                />
              </div>

              {expandProfile && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={Styles.profileOptions}
                  ref={boxReffPofile}
                >
                  <div className={Styles.top}>
                    <img src={Images.AVA_DEFAULT} alt="profile-picture" />
                    <div>
                      <span>{username || "-"}</span>
                      <p>{role || "-"}</p>
                    </div>
                  </div>
                  <div className={Styles.divider} />
                  <div className={Styles.bottom}>
                    {[
                      {
                        text: "Profile",
                        hidden: true,
                        onClick: () => {},
                      },
                      {
                        text: "Setting",
                        hidden: true,
                        onClick: () => {},
                      },
                      {
                        text: "Logout",
                        hidden: false,
                        onClick: () => {
                          setTimeout(() => {
                            localStorage?.clear();
                            sessionStorage?.clear();
                          }, 200);
                          navigate("/login");
                        },
                      },
                    ]
                      ?.filter((obj) => !obj?.hidden)
                      ?.map((option, i) => (
                        <div key={i} onClick={option?.onClick}>
                          {option?.text}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TopBar);
