import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import SchemaAbsenceSetting from "@Organism/Settings/Schema";
import { SkeletonSchemaSetting } from "@Organism/Skeletons/Settings/Schema";
import { getAllSchemaByClientCode } from "@Services/attendenceSchema/getAllSchemaByClientCode";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const getAllDataSchema = getAllSchemaByClientCode(page, limit);

  return defer({
    getAllDataSchema: getAllDataSchema,
  });
};

export default function SchemaAbsenceSettingPage() {
  const { getAllDataSchema } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonSchemaSetting />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={getAllDataSchema}
      >
        {(val) => <SchemaAbsenceSetting data={val} />}
      </Await>
    </Suspense>
  );
}
