import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";
import AttendanceDetailsLayout from "@Organism/Attendance/AttendanceDetails";
import ChooseClientPage from "@Organism/Client";
import EmergencySignaLayout from "@Organism/EmergencySignal";
import MainLayout from "@Organism/Main";
import PDFPreview from "@Organism/PDF/Preview";
import ReportIncident from "@Organism/Report/Incident";
import DetailReportIncident from "@Organism/Report/Incident/Detail";
import ReportPatrol from "@Organism/Report/Patrol";
import DetailReportPatrol from "@Organism/Report/Patrol/Detail";
import SkeletonDetailClientPage from "@Organism/Skeletons/DetailClientPage";
import { SkeletonDetailReport } from "@Organism/Skeletons/Reports/Detail";
import { SkeletonSchemaSetting } from "@Organism/Skeletons/Settings/Schema";
import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import SkeletonDetailInfoLocation from "@Organism/Skeletons/WorkLocations/Information/Detail";
import { SkeletonListMembersLocation } from "@Organism/Skeletons/WorkLocations/ListMembers/List";
import { SkeletonDetailPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/Detail";
import { SkeletonListScheduleLocation } from "@Organism/Skeletons/WorkLocations/WorkSchedules/List";
import AttendanceDetailsPage, {
  attendanceDetailsLoader,
} from "@Pages/Attendance/AttendanceDetails";
import { attendanceListLoader } from "@Pages/Attendance/AttendanceList";
import LoginPage from "@Pages/Auth/Login";
import { emergencySignalLoader } from "@Pages/EmergencySignal";
import AddEmployeePage from "@Pages/Employee/Add";
import DetailEmployeePage, {
  loader as dataDetailPersonil,
} from "@Pages/Employee/Detail";
import { loader as getAllDataPersonil } from "@Pages/Employee/List";
import PrivacyPolicyPage from "@Pages/PrivacyPolicy";
import ReportPage from "@Pages/Report";
import { activityReportLoader } from "@Pages/Report/Activity";
import { dataReportDetailActivityLoader } from "@Pages/Report/Activity/Detail";
import { incidentReportLoader } from "@Pages/Report/Incident";
import { incidentReportDetailLoader } from "@Pages/Report/Incident/Detail";
import { patrolReportLoader } from "@Pages/Report/Patrol";
import { patrolReportDetailLoader } from "@Pages/Report/Patrol/Detail";
import ReportSOSPage from "@Pages/Report/SOS";
import DetailReportSOSPage from "@Pages/Report/SOS/Detail";
import SettingsPage from "@Pages/Settings";
import { loader as getAllDataSchemaSetting } from "@Pages/Settings/Schema";
import LocationPage from "@Pages/WorkLocation";
import { loader as getDataDetailGuardPost } from "@Pages/WorkLocation/GuardPos/Detail";
import { loader as getDataDetailInfoWorkLocation } from "@Pages/WorkLocation/ListLocation/Detail";
// import { loader as getAllDataInfoWorkLocation } from "@Pages/WorkLocation/ListLocation/List";
import { loader as getDataMembersPersonil } from "@Pages/WorkLocation/ListMembers";
import { loader as getDataDetailPatrolPoint } from "@Pages/WorkLocation/PatrolPoint/Detail";
import { loader as getAllDataSchedule } from "@Pages/WorkLocation/WorkSchedule/Detail";
import { loader as getDataWorkSchedulePage } from "@Pages/WorkLocation/WorkSchedule/List";
import { loader as getDetailClientPage } from "@Pages/DetailClient";
import DetailWorkSchedulePage from "@Pages/WorkSchedule/Detail";
import SettingWorkSchedulePage from "@Pages/WorkSchedule/Setting";
import loginLoader from "pages/Auth/Login";
import { lazy, Suspense } from "react";
import ListGuardPos from "@Organism/WorkLocation/GuardPos/List";
import ListPatrolPoint from "@Organism/WorkLocation/PatrolPoint/List";
import ListLocation from "@Organism/WorkLocation/ListLocation/List";
import Announcements from "@Organism/Announcement";

const DetailClientPage = lazy(() => import("@Pages/DetailClient"));

const ListLocationPage = lazy(() =>
  import("@Pages/WorkLocation/ListLocation/List")
);
const DetailLocationPage = lazy(() =>
  import("@Pages/WorkLocation/ListLocation/Detail")
);
const ListGuardPosPage = lazy(() =>
  import("@Pages/WorkLocation/GuardPos/List")
);
const DetailGuardPosPage = lazy(() =>
  import("@Pages/WorkLocation/GuardPos/Detail")
);
const ListPatrolPointPage = lazy(() =>
  import("@Pages/WorkLocation/PatrolPoint/List")
);
const DetailPatrolPointPage = lazy(() =>
  import("@Pages/WorkLocation/PatrolPoint/Detail")
);
const ListMembersPage = lazy(() => import("@Pages/WorkLocation/ListMembers"));

const ListLocationWorkSchedulePage = lazy(() =>
  import("@Pages/WorkLocation/WorkSchedule/List")
);
const DetailLocationWorkSchedulePage = lazy(() =>
  import("@Pages/WorkLocation/WorkSchedule/Detail")
);

const ListEmployeePage = lazy(() => import("@Pages/Employee/List"));
const SchemaAbsenceSettingPage = lazy(() => import("@Pages/Settings/Schema"));

const AttendanceListPage = lazy(() =>
  import("@Pages/Attendance/AttendanceList")
);

const ReportIncidentPage = lazy(() => import("@Pages/Report/Incident"));
const DetailReportIncidentPage = lazy(() =>
  import("@Pages/Report/Incident/Detail")
);
const ReportPatrolPage = lazy(() => import("@Pages/Report/Patrol"));
const DetailReportPatrolPage = lazy(() =>
  import("@Pages/Report/Patrol/Detail")
);
const DetailReportActivityPage = lazy(() =>
  import("@Pages/Report/Activity/Detail")
);
const ReportActivityPage = lazy(() => import("@Pages/Report/Activity/index"));
const EmergencySignalPage = lazy(() => import("@Pages/EmergencySignal"));
const AnnouncementsPage = lazy(() => import("@Pages/Announcement"));
const { createBrowserRouter, redirect } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "pdf-preview",
    element: <PDFPreview />,
  },
  {
    path: "/login",
    loader: loginLoader,
    element: <LoginPage />,
  },
  {
    path: "client",
    element: <ChooseClientPage />,
  },
  {
    path: "/",
    loader: () => {
      if (!localStorage.getItem("accessToken")) {
        throw redirect("/login");
      }
      return null;
    },
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <h1>Halaman Dashboard</h1>,
        loader: () => {
          throw redirect("/detail-client");
        },
      },
      {
        path: "detail-client",
        loader: getDetailClientPage,
        element: (
          <Suspense fallback={<SkeletonDetailClientPage />}>
            <DetailClientPage />
          </Suspense>
        ),
      },
      {
        path: "work-location",
        element: <LocationPage />,
        children: [
          {
            index: true,
            // loader: getAllDataInfoWorkLocation,
            element: (
              <Suspense fallback={<ListLocation isLoader />}>
                <ListLocationPage />
              </Suspense>
            ),
          },
          {
            path: "detail/:id",
            children: [
              {
                index: true,
                loader: () => {
                  throw redirect("information");
                },
              },
              {
                path: "information",
                loader: getDataDetailInfoWorkLocation,
                element: (
                  <Suspense fallback={<SkeletonDetailInfoLocation />}>
                    <DetailLocationPage />
                  </Suspense>
                ),
              },
              {
                path: "guard-post",
                children: [
                  {
                    index: true,
                    // loader: getAllDataGuardPost,
                    element: (
                      <Suspense fallback={<ListGuardPos isInitialLoader />}>
                        <ListGuardPosPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":guardPostID",
                    loader: getDataDetailGuardPost,
                    element: (
                      <Suspense fallback={<SkeletonDetailGuardPos />}>
                        <DetailGuardPosPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "patrol-point",
                children: [
                  {
                    index: true,
                    // loader: getAllDataPatrolPoint,
                    element: (
                      <Suspense fallback={<ListPatrolPoint isLoader />}>
                        <ListPatrolPointPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":patrolPointID",
                    loader: getDataDetailPatrolPoint,
                    element: (
                      <Suspense fallback={<SkeletonDetailPatrolPoint />}>
                        <DetailPatrolPointPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "list-personil-and-squad",
                children: [
                  {
                    index: true,
                    loader: getDataMembersPersonil,
                    element: (
                      <Suspense fallback={<SkeletonListMembersLocation />}>
                        <ListMembersPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "work-schedule",
                children: [
                  {
                    index: true,
                    loader: getDataWorkSchedulePage,
                    element: (
                      <Suspense fallback={<SkeletonListScheduleLocation />}>
                        <ListLocationWorkSchedulePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "detail/:month",
                    element: (
                      <Suspense fallback={<SkeletonListScheduleLocation />}>
                        <DetailLocationWorkSchedulePage />
                      </Suspense>
                    ),
                    loader: getAllDataSchedule,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "list-members",
        children: [
          {
            index: true,
            loader: getAllDataPersonil,
            element: (
              <Suspense fallback={<SkeletonListEmployee />}>
                <ListEmployeePage />
              </Suspense>
            ),
          },
          {
            path: "add-employee",
            element: <AddEmployeePage />,
          },
          {
            path: ":employeeID",
            element: <DetailEmployeePage />,
            loader: dataDetailPersonil,
          },
        ],
      },
      {
        path: "attendance",
        children: [
          {
            index: true,
            loader: attendanceListLoader,
            element: (
              <Suspense fallback={<SkeletonListMembersLocation />}>
                <AttendanceListPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            loader: attendanceDetailsLoader,
            element: (
              <Suspense fallback={<AttendanceDetailsLayout isSkeleton />}>
                <AttendanceDetailsPage />
              </Suspense>
            ),
          },
          {
            path: "detail/:id",
            children: [
              {
                index: true,
                element: <DetailWorkSchedulePage />,
              },
              {
                path: "setting/:detailSettingID",
                element: <SettingWorkSchedulePage />,
              },
            ],
          },
        ],
      },
      {
        path: "report",
        element: <ReportPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("activity");
            },
          },
          {
            path: "activity",
            children: [
              {
                index: true,
                element: (
                  <Suspense
                    fallback={
                      <ReportPatrol isLoadingCounter isLoadingLocations />
                    }
                  >
                    <ReportActivityPage />
                  </Suspense>
                ),
                loader: activityReportLoader,
              },
              {
                path: "detail/:id",
                element: (
                  <Suspense fallback={<SkeletonDetailReport />}>
                    <DetailReportActivityPage />
                  </Suspense>
                ),
                loader: dataReportDetailActivityLoader,
              },
            ],
          },
          {
            path: "patrol",
            children: [
              {
                index: true,
                loader: patrolReportLoader,
                element: (
                  <Suspense
                    fallback={
                      <ReportPatrol isLoadingCounter isLoadingLocations />
                    }
                  >
                    <ReportPatrolPage />
                  </Suspense>
                ),
              },
              {
                path: "detail/:id",
                loader: patrolReportDetailLoader,
                element: (
                  <Suspense
                    fallback={
                      <DetailReportPatrol isLoadingDetails isLoadingMembers />
                    }
                  >
                    <DetailReportPatrolPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "incident",
            children: [
              {
                index: true,
                loader: incidentReportLoader,
                element: (
                  <Suspense
                    fallback={
                      <ReportIncident isLoadingCounter isLoadingLocations />
                    }
                  >
                    <ReportIncidentPage />
                  </Suspense>
                ),
              },
              {
                path: "detail/:id",
                loader: incidentReportDetailLoader,
                element: (
                  <Suspense
                    fallback={
                      <DetailReportIncident isLoadingDetails isLoadingMembers />
                    }
                  >
                    <DetailReportIncidentPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "sos",
            children: [
              {
                index: true,
                element: <ReportSOSPage />,
              },
              {
                path: "detail/:id",
                element: <DetailReportSOSPage />,
              },
            ],
          },
        ],
      },
      {
        path: "content-manage",
        element: <h1>Halaman Pengelolaan Konten</h1>,
      },
      {
        path: "salary",
        element: <h1>Halaman Slip Gaji</h1>,
      },
      {
        path: "employee-benefit",
        element: <h1>Halaman Tunjangan Karyawan</h1>,
      },
      {
        path: "announcements",
        element: (
          <Suspense fallback={<Announcements isLoader />}>
            <AnnouncementsPage />
          </Suspense>
        ),
      },

      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          {
            index: true,
            loader: () => {
              throw redirect("schema");
            },
          },
          {
            path: "company",
            element: <h1>Halaman pengaturan perushaan</h1>,
          },
          {
            path: "schema",
            loader: getAllDataSchemaSetting,
            element: (
              <Suspense fallback={<SkeletonSchemaSetting />}>
                <SchemaAbsenceSettingPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "coordinator-emergency/:hash",
    loader: emergencySignalLoader,
    element: (
      <Suspense fallback={<EmergencySignaLayout isLoading />}>
        <EmergencySignalPage />
      </Suspense>
    ),
  },
]);

export default router;
