import { SkeletonDetailPatrolPoint } from "@Organism/Skeletons/WorkLocations/PatrolPoint/Detail";
import DetailPatrolPoint from "@Organism/WorkLocation/PatrolPoint/Detail";
import { getDetailPatrolPoint } from "@Services/patrolPoint/getDetailPatrolPoint";
import moment from "moment";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const date = params.get("date") || moment(new Date()).format("YYYY-MM-DD");
  const { patrolPointID } = route?.params;
  const fetchDataDetailPatrolPoint = getDetailPatrolPoint(patrolPointID, date);

  return defer({
    getDataDetailPatrolPoint: fetchDataDetailPatrolPoint,
  });
};

export default function DetailPatrolPointPage() {
  const { getDataDetailPatrolPoint } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailPatrolPoint />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={getDataDetailPatrolPoint}
      >
        {(data) => <DetailPatrolPoint data={data?.data} />}
      </Await>
    </Suspense>
  );
}
