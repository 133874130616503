import { API } from "configs";

export function createAnnouncement(payload) {
  return new Promise(async (resolve, reject) => {
    const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));

    try {
      const response = await API.post(
        `/announcement/create?code=${CLIENT_KEY.code}`,
        payload,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
