import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import DetailLocation from "@Organism/WorkLocation/ListLocation/Detail";
import { getDetailInfoWorkLocation } from "@Services/workLocation/getDetailInfoWorkLocation";
import moment from "moment";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = (route) => {
  const { id } = route?.params;
  const params = new URLSearchParams(route?.request?.url);
  const currentDate =
    params?.get("date") || moment(new Date()).format("YYYY-MM-DD");
  const getDataDetail = getDetailInfoWorkLocation(id, currentDate);

  return defer({
    getData: getDataDetail,
  });
};

export default function DetailLocationPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailGuardPos />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={getData}
      >
        {(data) => <DetailLocation data={data?.data?.data} />}
      </Await>
    </Suspense>
  );
}
