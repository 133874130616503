import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import SearchBar from "@Atom/SearchBar";
import Spinner from "@Atom/Spinner";
import Table from "@Molecule/Table";
import React from "react";
import Styles from "./style.module.scss";
export default function ModalTableClient({
  isOpenModalClient = false,
  setIsOpenModalClient = () => {},
  isSavedClient = false,
  handleSaveClient = () => {},
  setSearchQuery = () => {},
  searchQuery,
  limit,
  setLimit,
  page,
  setPage,
  dataTable = {
    data: "",
    columns: [],
    totalData: "",
  },
  isDisabledBtnSave = false,
  client = {
    name: "",
    id: "",
    location: "",
    code: "",
  },
  isChooseClient = false,
  isSuccessChangeClient = false,
  isLoading = false,
}) {
  return (
    <div>
      <Modal
        isOpen={isOpenModalClient}
        onClose={() => setIsOpenModalClient(false)}
      >
        <div
          className={Styles.viewModalChangeClient}
          style={!isSavedClient ? { maxHeight: "768px" } : { undefined }}
        >
          <div className={Styles.header}>
            <div>
              <Icon
                icon={"building"}
                color={"#2C5364"}
                size={"24"}
                className={Styles.logo}
                style={{ flexShrink: "0" }}
              />
              <span>Ganti Klien</span>
            </div>
            {!isChooseClient && (
              <Icon
                icon={"close-circle"}
                size={"16"}
                className={Styles.closeIcon}
                onClick={() => setIsOpenModalClient(false)}
              />
            )}
          </div>
          {isSavedClient ? (
            <div className={Styles.loadClient}>
              {isSuccessChangeClient ? (
                <Icon icon={"tick-circle"} color={"#22970F"} size={"45"} />
              ) : (
                <Spinner width="40px" />
              )}
              <div>
                <p>
                  {isSuccessChangeClient
                    ? "Data berhasil dimuat"
                    : "Memuat data..."}
                </p>
                <div>
                  <Icon
                    icon={"building"}
                    color={"#2C5364"}
                    size={"24"}
                    className={Styles.logo}
                    style={{ flexShrink: "0" }}
                  />
                  <span>{client?.name}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles.wrapTable}>
              <p className={Styles.description}>
                <span>
                  Silahkan pilih pada daftar klien yang tersedia untuk
                  melanjutkan.
                </span>
              </p>
              <div style={{ width: "99%" }}>
                <SearchBar
                  placeholder="Cari klien"
                  setValue={setSearchQuery}
                  value={searchQuery}
                />
              </div>
              <Table
                data={dataTable.data || []}
                columns={dataTable.columns}
                totalData={dataTable.totalData}
                totalPage={Math.ceil(dataTable.totalData / limit) || 0}
                className={Styles.tableRadius}
                withNumbering
                withPagination
                limit={isLoading ? 10 : limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                isActive={client?.name?.toString()}
                isActiveKey="name"
                heightEmpty="36px"
                isLoading={isLoading}
              />
            </div>
          )}
          <div className={Styles.saveButton}>
            <Button
              isDisabled={isDisabledBtnSave}
              text={
                isSuccessChangeClient
                  ? "Selesai"
                  : isSavedClient
                  ? "Memuat"
                  : "Simpan"
              }
              onClick={isSavedClient ? () => {} : () => handleSaveClient()}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
