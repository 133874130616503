import { SkeletonListScheduleLocation } from "@Organism/Skeletons/WorkLocations/WorkSchedules/List";
import DetailLocationWorkSchedule from "@Organism/WorkLocation/WorkSchedule/Detail";
import { getAllMembersPlotting } from "@Services/workSchedule/getAllMembersPlotting";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const { id: locationID, month: monthString } = route?.params;
  const activeFilterYear = +sessionStorage.getItem("yearScheduleWorkLocation");
  const year = activeFilterYear;
  const type = [];
  params.forEach((value, key) => {
    if (key === "type") {
      type.push(value);
    }
  });
  const searchQuery = params?.get("search") || "";

  const getAllDataSchedule = getAllMembersPlotting(
    locationID,
    monthString,
    year,
    type,
    searchQuery
  );

  return defer({
    allDataSchedule: getAllDataSchedule,
  });
};

export default function DetailLocationWorkSchedulePage() {
  const { allDataSchedule } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListScheduleLocation />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={allDataSchedule}
      >
        {(data) => <DetailLocationWorkSchedule data={data} />}
      </Await>
    </Suspense>
  );
}
