import React, { useCallback, useMemo } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
export default function TabHeader({
  text = "Placeholder",
  style,
  isSorted = false,
  sort,
  setSort = () => {},
  justifyContent = "flex-start",
  column = "name",
}) {
  const handleSort = useCallback(() => {
    if (sort?.column) {
      if (sort?.type) {
        if (sort?.type === "ASC" && sort?.column === column) {
          setSort({
            column: column,
            type: "DESC",
          });
        } else if (sort?.type === "DESC" && sort?.column === column) {
          setSort({
            column: column,
            type: "ASC",
          });
        } else {
          setSort({
            column: column,
            type: "ASC",
          });
        }
      } else {
        setSort({
          column: column,
          type: "ASC",
        });
      }
    } else {
      setSort({
        column: column,
        type: "ASC",
      });
    }
  }, [column, setSort, sort?.column, sort?.type]);

  const handleIconSort = useMemo(() => {
    if (sort?.column && sort?.type) {
      if (sort?.type === "ASC" && sort?.column === column)
        return (
          <Icon
            icon={"sort-asc"}
            size={"16"}
            color={sort?.column === column ? "#6C757D" : "#3E4856"}
            onClick={handleSort}
            style={{ cursor: "pointer" }}
          />
        );
      else
        return (
          <Icon
            icon={"sort"}
            size={"16"}
            color={sort?.column === column ? "#6C757D" : "#3E4856"}
            onClick={handleSort}
            style={{ cursor: "pointer" }}
          />
        );
    } else {
      return (
        <Icon
          icon={"sort"}
          size={"16"}
          color={sort?.column === column ? "#6C757D" : "#3E4856"}
          onClick={handleSort}
          style={{ cursor: "pointer" }}
        />
      );
    }
  }, [sort, column, handleSort]);

  return (
    <div className={Styles.containerTabHeader} style={style}>
      <div className={Styles.wrapperTab} style={{ justifyContent }}>
        <div>{text}</div>
        {isSorted && handleIconSort}
      </div>
    </div>
  );
}
