import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Button from "@Atom/Button";

export default function CustomEmptyHandlerPage({
  onClick,
  title = "Lokasi Kerja Tidak Tersedia",
  description = " Silahkan lakukan penambahan lokasi kerja terlebih dahulu untuk melakukan pengaturan jadwal kerja",
  btnText = "Tambah Lokasi Kerja",
  images = Images.BUILDING_EMPTY,
  isAddButton = true,
  icon = "",
}) {
  return (
    <main className={Styles.containerIsEmpty}>
      <section className={Styles.wrapperEmpty}>
        <div className={Styles.headerText}>
          <img
            src={images}
            alt="image-empty-handler"
            style={{ objectFit: "contain" }}
          />
        </div>
        <div className={Styles.headerText}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        {isAddButton && (
          <Button
            text={btnText}
            isAddButton
            isLeftIcon
            icon={icon}
            onClick={onClick}
          />
        )}
      </section>
    </main>
  );
}
