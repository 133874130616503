import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import DetailReportIncident from "@Organism/Report/Incident/Detail";
import { SkeletonDetailReport } from "@Organism/Skeletons/Reports/Detail";
import { getOnDutyMembers } from "@Services/report/getOnDutyMembers";
import { getReportDetails } from "@Services/report/getReportDetail";
import { plotAliasDynamicTabs } from "@Services/report/plotAliasDinamycTabs";
import moment from "moment";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const incidentReportDetailLoader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const { id } = route?.params;
  const date =
    params?.get("date") || moment(new Date("2024-11-12"))?.format("YYYY-MM-DD");
  const status = params?.get("status")
    ? params?.get("status")?.includes("-")
      ? params?.get("status")?.split("-")
      : [params?.get("status")]
    : [];
  const employeeID = params?.get("employeeID")
    ? params?.get("employeeID")?.includes("-")
      ? params?.get("employeeID")?.split("-")
      : [params?.get("employeeID")]
    : [];
  const shift = params?.get("shift") || "";

  return defer({
    packageDetails: getReportDetails(
      "events",
      1,
      10,
      id,
      date,
      status,
      employeeID,
      shift
    ),
    packageMembers: getOnDutyMembers("EVENT", id, date),
    tabsAlias: plotAliasDynamicTabs(id, date),
  });
};

export default function DetailReportIncidentPage() {
  const { packageDetails, packageMembers, tabsAlias } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailReport />}>
      <Await
        resolve={packageMembers}
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
      >
        {(packageMembers) => (
          <Suspense fallback={<SkeletonDetailReport />}>
            <Await
              resolve={packageDetails}
              errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
            >
              {(packageDetails) => (
                <Suspense fallback={<SkeletonDetailReport />}>
                  <Await
                    resolve={tabsAlias}
                    errorElement={
                      <AsyncErrorPage height="calc(100vh - 56px)" />
                    }
                  >
                    {(packageTabs) => (
                      <DetailReportIncident
                        packageDetails={packageDetails}
                        packageMembers={packageMembers?.data?.data}
                        tabsAlias={packageTabs?.data?.data}
                      />
                    )}
                  </Await>
                </Suspense>
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
