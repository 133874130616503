import React from "react";
import Styles from "./style.module.scss";
import Skeleton from "@Atom/Skeleton";
import SkeletonTable from "@Molecule/Skeleton/Table";
export default function SkeletonListEmployee() {
  return (
    <div className={Styles.containerSkeletonListEmployee}>
      <div className={Styles.wrapperSkeletonListEmployee}>
        <div style={{ width: "10%" }}>
          <Skeleton isV2 />
        </div>
        <div className={Styles.viewRow}>
          <div style={{ width: "20%" }}>
            <Skeleton isV2 />
          </div>
          <div style={{ width: "20%" }}>
            <Skeleton isV2 />
          </div>
        </div>

        <div className={Styles.viewRowBetween}>
          <div className={Styles.viewRow}>
            <Skeleton isV2 />
            <Skeleton isV2 />
            <Skeleton isV2 />
          </div>
          <div style={{ width: "10%" }}>
            <Skeleton isV2 />
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SkeletonTable />
        </div>
      </div>
    </div>
  );
}
