import Icon from "@Atom/Icon";
import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import Styles from './style.module.scss';
import Skeleton from "@Atom/Skeleton";
import './style.css'

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#DEE2E7",
  progressColor: "#919EB0",
  cursorColor: "transparent",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 22,
  normalize: true,
  partialRender: true,
  backend: "WebAudio",
});

export default function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Create the WaveSurfer instance only once
    if (!wavesurfer.current) {
      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
    }

    // Load the audio file
    wavesurfer.current.load(url);

    // Listen for ready state
    wavesurfer.current.on("ready", () => {
      const audioDuration = wavesurfer.current.getDuration();
      setIsLoading(false);
      setDuration(formatTime(audioDuration));
      console.log("WaveSurfer is ready!");
    });

    // Update the current time during playback
    wavesurfer.current.on("audioprocess", () => {
      const current = wavesurfer.current.getCurrentTime();
      setCurrentTime(formatTime(current));
    });

    // Reset the player when the audio ends
    wavesurfer.current.on("finish", () => {
      setPlaying(false); // Set playing state to false (paused)
      setCurrentTime("0:00"); // Reset the current time
      wavesurfer.current.seekTo(0); // Reset the playback position to the start
    });

    // Cleanup when component unmounts
    return () => {
      if (wavesurfer.current && !isLoading) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
    };
  }, [isLoading, url]);

  const handlePlayPause = () => {
    setPlaying((prevPlaying) => !prevPlaying);
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  };

  // Format time from seconds to MM:SS
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  return (
    <div className={Styles.container}>
      <button onClick={handlePlayPause}>
        <Icon color={'#2C5364'} icon={playing ? "pause-outline" : "play-bold"} size={24} />
      </button>

      <div className={Styles.wrapper}>
        {
          isLoading
          &&
          <div style={{ position: isLoading ? 'absolute' : 'auto', width: '100%' }}>
            <Skeleton h="22px" />
          </div>

        }
        <div id="waveform" className={isLoading ? 'transparent' : ''} ref={waveformRef} />

        <div className={Styles.time}>
          <span>{currentTime}</span>
          <span>{duration}</span>
        </div>
      </div>
    </div>
  );
}
