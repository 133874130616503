import { API } from "configs";

export function createCoordinatorExternal(payload = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(`/externalcoordinator`, payload, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
