import { SkeletonDetailGuardPos } from "@Organism/Skeletons/WorkLocations/GuardPos/Detail";
import DetailGuardPos from "@Organism/WorkLocation/GuardPos/Detail";
import { getDetailGuardPost } from "@Services/guardPost/getDetailGuardPost";
import moment from "moment";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const date = params?.get("date") || moment(new Date()).format("YYYY-MM-DD");
  const { guardPostID } = route?.params;
  const fetchDataDetailGuardPost = getDetailGuardPost(guardPostID, date);

  return defer({
    getData: fetchDataDetailGuardPost,
  });
};

export default function DetailGuardPosPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonDetailGuardPos />}>
      <Await
        resolve={getData}
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
      >
        {(value) => <DetailGuardPos data={value?.data} />}
      </Await>
    </Suspense>
  );
}
