import React, { useEffect, useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
export default function DropdownMoreCircle({
  openChoose,
  setOopenChoose = () => {},
  id,
  isAlreadySquad = false,
  onClickDelete = () => {},
  onClickSquad = () => {},
  onClickDeleteSquad = () => {},
  tabsActive,
}) {
  const handleOpenDropdown = () => {
    if (openChoose === id) {
      setOopenChoose(null);
    } else {
      setOopenChoose(id);
    }
  };
  const dropdownItems = [
    {
      hide: tabsActive === 1,
      icon: "card-add",
      title: (isAlreadySquad ? "Ubah" : "Setup") + " Regu",
      onClick: onClickSquad,
    },
    {
      icon: "trash",
      title: tabsActive === 1 ? "Hapus Regu" : "Hapus",
      onClick: tabsActive === 1 ? onClickDeleteSquad : onClickDelete,
    },
  ];

  const reff = useRef();
  const boxReff = useOutsideClick(reff);
  useEffect(() => {
    if (boxReff && openChoose) {
      setOopenChoose(false);
    }
  }, [boxReff, openChoose, setOopenChoose]);

  return (
    <div className={Styles.containerDropdownMoreCircle}>
      <div
        className={Styles.target}
        onClick={handleOpenDropdown}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Icon icon={"more-circle"} color={"#2C5364"} size={16} />
      </div>
      {openChoose === id && (
        <div className={Styles.wrapperDropdownMoreCirle} ref={reff}>
          {dropdownItems.map(
            (item, idx) =>
              !item.hide && (
                <div key={idx} onClick={item.onClick}>
                  <Icon icon={item.icon} size={14} color={"#2C5364"} />
                  <span>{item.title}</span>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}
