import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import EmergencySignaLayout from "@Organism/EmergencySignal";
import { getEmergencySignalByHash } from "@Services/emergencySignalCoordinator/getEmergencySignalByHash";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const emergencySignalLoader = (route) => {
  const { hash } = route?.params

  return defer({
    packageDetails: getEmergencySignalByHash(hash)
  })
}

export default function EmergencySignalPage() {
  const { packageDetails } = useLoaderData()

  return (
    <Suspense fallback={<EmergencySignaLayout isLoading />}>
      <Await
        errorElement={<AsyncErrorPage height="100vh" />}
        resolve={packageDetails}
      >
        {(packageDetails) => (
          <EmergencySignaLayout packageDetails={packageDetails?.data?.data} />
        )}
      </Await>
    </Suspense>
  )
}