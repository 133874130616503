import React, { Suspense } from "react";
import ListMembers from "@Organism/WorkLocation/ListMembers";
import { getAllMembersPersonil } from "@Services/workLocation/getMembersPersonil";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getSquadPerLocation } from "@Services/squad/getSquadPerLocation";
import { SkeletonListMembersLocation } from "@Organism/Skeletons/WorkLocations/ListMembers/List";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const { id: locationID } = route?.params;
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const searchQuery = params?.get("search") || "";

  const getDataMembers = getAllMembersPersonil(
    locationID,
    page,
    limit,
    searchQuery
  );

  const getDataSquad = getSquadPerLocation(
    locationID,
    page,
    limit,
    searchQuery
  );

  return defer({
    getDataMembers: getDataMembers,
    getDataSquad: getDataSquad,
  });
};

export default function ListMembersPage() {
  const { getDataMembers, getDataSquad } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListMembersLocation />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={getDataMembers}
      >
        {(dataListPersonil) => (
          <Suspense fallback={<SkeletonListMembersLocation />}>
            <Await
              errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
              resolve={getDataSquad}
            >
              {(dataListSquad) => (
                <div
                  style={{
                    width: "100%",
                    height: "calc(100vh - 56px)",
                    background: "rgba(212, 212, 232, 0.10)",
                  }}
                >
                  <ListMembers
                    data={dataListPersonil}
                    dataSquad={dataListSquad}
                  />
                </div>
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
