import Map from '@Molecule/Map'
import VoiceCard from '@Molecule/VoiceCard'
import Images from '@Theme/Images'
import Styles from './style.module.scss'
import { fileBaseUrl } from 'configs'
import moment from 'moment'
import Skeleton from '@Atom/Skeleton'

export default function EmergencySignaLayout({
  isLoading = false,
  packageDetails
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.header}>
          <img className={Styles.mws} src={Images.MWS_SECURITY_LOGO} alt='' />
          <h3>🚨 SINYAL DARURAT</h3>
          <img className={Styles.sec} src={Images.SECURITY_LOGO} alt='' />
        </div>
        <div className={Styles.content}>
          <Description
            title='Nama anggota'
            description={packageDetails?.employee?.name}
            isLoading={isLoading}
          />
          <Description
            title='Tanggal & Waktu'
            description={moment(packageDetails?.createdAt)?.format('LLL')}
            isLoading={isLoading}
          />
          <Description
            title='Lokasi kerjadian'
            customContent={
              <Map
                height='258px'
                isLoading={isLoading}
                coordinate={{
                  latitude: packageDetails?.latitude,
                  longitude: packageDetails?.longitude
                }}
              />
            }
          />

          {
            packageDetails?.audio?.length
            &&
            <div className={Styles.audios}>
              {
                packageDetails?.audio?.map((audio, i) => (
                  <VoiceCard
                    // audioUrl='https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3'
                    audioUrl={fileBaseUrl + audio?.URI}
                  />

                ))
              }
            </div>
          }

        </div>
      </div>
    </div>
  )
}

const Description = ({
  title = '',
  description = '',
  customContent,
  isLoading = false
}) => {

  return (
    <div className={Styles.description}>
      <span>{title}</span>
      {
        customContent
          ?
          customContent
          :
          (
            isLoading
              ?
              <Skeleton w='250px' />
              :
              <p>{description}</p>
          )
      }
    </div>
  )

}
