import ImagesNoShift from "@Assets/Images/empty-handler-no-shift.png";
import Button from "@Atom/Button";
import Checkbox from "@Atom/Checkbox";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import SelectDate from "@Atom/SelectDate";
import Spinner from "@Atom/Spinner";
import useQuery from "@Hooks/useQuery";
import { getReportDetails } from "@Services/report/getReportDetail";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import moment from "moment";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import { fileBaseUrl } from "configs";
import Images from "@Theme/Images";
import HistoryActivity from "@Organism/WorkLocation/GuardPos/Detail/HistoryActivity";
import { makeRandomString } from "helpers/makeRandomString";
import useOutsideClick from "@Hooks/useOutsideClick";
import ButtonDownloadReport from "@Molecule/_modal/ButtonDownloadReport";
import Skeleton, { CircleSkeleton } from "@Atom/Skeleton";
const ModalActivity = lazy(() =>
  import("@Organism/WorkLocation/GuardPos/Detail/ModalDetail/Activity")
);
export default function DetailReportPatrol({
  packageDetails,
  packageMembers,
  isLoadingDetails = false,
  isLoadingMembers = false,
  tabsAlias,
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const { pathname } = useLocation();
  const locationName = query.get("locationName");
  const defaultDate =
    query?.get("date") || moment(new Date())?.format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const selectedConvertDate = moment(selectedDate)
    .format("L")
    .split("/")
    .reverse()
    .join("-");

  const tabsToShow = () => {
    if (tabsAlias?.length === 0) return [];
    let listedTabs = [];
    tabsAlias?.forEach((shift) => {
      const { plotAlias: s } = shift;
      if (s === "P") {
        listedTabs.push({ label: "Shift Pagi", value: "P" });
      }
      if (s === "S") {
        listedTabs.push({ label: "Shift Siang", value: "S" });
      }
      if (s === "M") {
        listedTabs.push({ label: "Shift Malam", value: "M" });
      }
      if (s === "NS") {
        listedTabs.push({ label: "Non-Shift", value: "NS" });
      }
    });
    return listedTabs;
  };

  const shifts = tabsToShow();
  const defaultShift = query?.get("shift") || "P";
  const [shift, setShift] = useState(defaultShift);
  const [search, setSearch] = useState("");

  const defaultStatus = useMemo(() => {
    const queried = query.get("status");
    if (queried?.includes("-")) {
      return queried.split("-");
    } else {
      return queried ? [queried] : [];
    }
  }, [query]);

  const [status, setStatus] = useState(defaultStatus);

  const defaultEmployees = useMemo(() => {
    const queried = query.get("employeeID");
    if (queried?.includes("-")) {
      return queried.split("-");
    } else {
      return queried ? [queried] : [];
    }
  }, [query]);

  const [employees, setEmployees] = useState(defaultEmployees);

  const fixedIsLoadingDetails = useMemo(() => {
    if (isLoadingDetails || isLoadingMembers) {
      return true;
    }
    return false;
  }, [isLoadingDetails, isLoadingMembers]);
  const { id } = useParams();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataReport, setDataReport] = useState(packageDetails?.data);
  const [totalPage, setTotalPage] = useState(packageDetails?.totalPage);
  const [page, setPage] = useState(1);
  const observerRef = useRef(null); // Ref for the intersection observer target

  useEffect(() => {
    if (packageDetails) {
      setDataReport(packageDetails?.data);
      setTotalPage(packageDetails?.totalPage);
    }
  }, [packageDetails, packageDetails?.data, packageDetails?.totalPage]);

  const fetchMore = useCallback(async () => {
    if (isLoadingMore || page > totalPage) return;
    setIsLoadingMore(true);
    try {
      const { data } = await getReportDetails(
        "patrols",
        page + 1,
        10,
        id,
        selectedDate,
        [],
        [],
        "P"
      );
      setDataReport((prevData) => [...prevData, ...data.data]);
      setTotalPage(data?.totalPage);

      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [id, isLoadingMore, page, selectedDate, totalPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchMore();
        }
      },
      {
        root: null, // Default: viewport or scrollable parent
        rootMargin: "0px",
        threshold: 1.0, // Trigger when 100% of the element is visible
      }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [isLoadingMore, isLoadingDetails, fetchMore]);

  const templateData = useMemo(() => {
    return dataReport?.length
      ? dataReport?.map((obj) => {
          return {
            id: obj?.patrolID + makeRandomString(5),
            name: obj?.employee?.name,
            profilePicture: obj?.employee?.profilePicture
              ? fileBaseUrl + obj?.employee?.profilePicture
              : Images.AVA_DEFAULT,
            date: moment(obj?.createdAt).format("dddd, DD MMMM YYYY"),
            time: moment(obj?.createdAt).format("HH:mm:ss"),
            shift: obj?.shift,
            activity: capitalizeEachWord(obj?.checkpoint?.description || "-"),
            statusLocation: capitalizeEachWord(obj?.checkpoint?.status),
            images: obj?.checkpoint?.images,
            reportDescription: obj?.checkpoint?.description,
          };
        })
      : [];
    // return [
    //   {
    //     id: 1,
    //     name: "Anton Sinaga",
    //     profilePicture: "https://placehold.co/20x20",
    //     date: "Senin, September 12 2024",
    //     time: "14:23:46, WIB",
    //     shift: "Pagi",
    //     activity: "Aktifitas 1",
    //     statusLocation: "Aman",
    //     frontImage: "https://placehold.co/650x253/orange/blue",
    //     bottomImage: "https://placehold.co/650x253",
    //     rightImage: "https://placehold.co/650x253",
    //     leftImage: "https://placehold.co/650x253",
    //     reportDescription: "Lorem ipsum .",
    //   },

    // ]
  }, [dataReport]);

  const [isOpenModalActivity, setIsOpenModalActivity] = useState(false);
  const [isTransition, setTransition] = useState(false);
  useEffect(() => {
    if (isOpenModalActivity) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenModalActivity]);

  const isValueDifferent = useMemo(() => {
    return (
      defaultDate !== moment(selectedDate)?.format("YYYY-MM-DD") ||
      defaultShift !== shift ||
      defaultStatus?.length !== status?.length ||
      defaultEmployees?.length !== employees?.length
    );
  }, [
    defaultDate,
    defaultEmployees?.length,
    defaultShift,
    defaultStatus?.length,
    employees?.length,
    selectedDate,
    shift,
    status?.length,
  ]);

  useEffect(() => {
    if (isValueDifferent) {
      navigate(
        `${pathname}?locationName=${query?.get("locationName")}&date=${
          selectedDate
            ? moment(new Date(selectedDate))?.format("YYYY-MM-DD")
            : ""
        }&shift=${shift}&status=${status?.join(
          "-"
        )}&employeeID=${employees?.join("-")}`,
        { replace: true }
      );
    }
  }, [
    employees,
    isValueDifferent,
    navigate,
    pathname,
    query,
    selectedDate,
    shift,
    status,
  ]);

  const listMembersOfDuty = useMemo(() => {
    return packageMembers?.length
      ? packageMembers?.map((obj) => {
          return {
            id: obj?.ID,
            image: Images.AVA_DEFAULT,
            name: obj?.name,
            pos: obj?.guardpos || "-",
            level: obj?.role || "-",
          };
        })
      : [];
  }, [packageMembers]);

  const filteredMembers = useMemo(() => {
    return listMembersOfDuty?.filter((obj) =>
      obj?.name?.toLowerCase()?.includes(search?.toLowerCase())
    );
  }, [listMembersOfDuty, search]);

  const selectedMembers = useMemo(() => {
    return listMembersOfDuty?.filter((obj) => employees.includes(obj.id));
  }, [employees, listMembersOfDuty]);

  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);

  const refBox = useRef(null);
  const isOutsideClick = useOutsideClick(refBox);

  useEffect(() => {
    if (isOutsideClick && isOpenModalDownload) {
      setIsOpenModalDownload(false);
    }
  }, [isOpenModalDownload, isOutsideClick]);

  return (
    <div className={Styles.containerDetailReportActivity}>
      <header className={`${Styles.header}`} style={{ position: "relative" }}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/report/patrol")}
            className={Styles.back}
          />
          <h3>{locationName}</h3>
        </div>
        <Button
          isAddButton
          isLeftIcon
          icon={"download"}
          style={{ background: "transparent", color: "#2C5364" }}
          colorIcon={"#2C5364"}
          text="Download Report"
          onClick={() => setIsOpenModalDownload(!isOpenModalDownload)}
        />
        {isOpenModalDownload && (
          <ButtonDownloadReport
            type={"patrols"}
            date={selectedConvertDate}
            month={moment(selectedDate).format("MMMM")}
            year={moment(selectedDate).format("YYYY")}
            ref={refBox}
          />
        )}
      </header>
      <div className={Styles.wrapViewContain}>
        <div className={Styles.left}>
          <div>
            <SelectDate
              setValue={setSelectedDate}
              value={selectedDate}
              autoSave
            />
            <div className={Styles.divider}></div>
            <div className={Styles.selectStatusLocation}>
              <strong>Status Lokasi</strong>
              {["AMAN", "SIAGA", "WASPADA"].map((c, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    if (status?.find((obj) => obj === c)) {
                      setStatus((prev) =>
                        [...prev]?.filter((obj) => obj !== c)
                      );
                    } else {
                      setStatus((prev) => [...prev, c]);
                    }
                  }}
                >
                  <Checkbox
                    isChecked={status?.find((obj) => obj === c) ? true : false}
                  />
                  <span>{capitalizeEachWord(c)}</span>
                </div>
              ))}
            </div>
            <div className={Styles.divider}></div>
          </div>

          <div className={Styles.listPersonil}>
            <strong>Daftar Anggota Bertugas</strong>
            <SearchBar
              value={search}
              setValue={setSearch}
              size="s"
              placeholder="Cari Anggota"
            />
            <div
              className={`${Styles.selectStatusLocation} ${Styles.overflow}`}
            >
              {isValueDifferent ? (
                Array.from({ length: 3 })?.map((l, idx) => (
                  <div key={idx}>
                    <Checkbox />
                    <div className={Styles.profile} style={{ width: "100%" }}>
                      <CircleSkeleton />
                      <Skeleton />
                    </div>
                  </div>
                ))
              ) : filteredMembers?.length > 0 ? (
                filteredMembers?.map((l, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      if (employees?.find((obj) => obj === l?.id)) {
                        setEmployees((prev) =>
                          [...prev]?.filter((obj) => obj !== l?.id)
                        );
                      } else {
                        setEmployees((prev) => [...prev, l?.id]);
                      }
                    }}
                  >
                    <Checkbox
                      isChecked={
                        employees?.find((obj) => obj === l?.id) ? true : false
                      }
                    />
                    <div className={Styles.profile}>
                      <img src={l?.image} alt="profile-picture" />
                      <div>
                        <strong>{l?.name}</strong>
                        <span>{l?.level}</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={Styles.noOption}>no option</div>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.right}>
          {!!selectedMembers?.length && (
            <div className={Styles.wrapSelectedPersonil}>
              {selectedMembers.map((m, idx) => (
                <Button
                  key={`members-checked-${idx}`}
                  isNoBorder
                  style={{
                    background: "#E6F0F4",
                    color: "#2C5364",
                    borderRadius: "20px",
                  }}
                  onClickIcon={() => {
                    setEmployees((prev) =>
                      [...prev]?.filter((obj) => obj !== m?.id)
                    );
                  }}
                  icon={"close-circle"}
                  iconSize={"10"}
                  colorIcon={"#2C5364"}
                  text={m?.name}
                />
              ))}
            </div>
          )}

          <div className={Styles.rightContain}>
            {isValueDifferent ? (
              <div className={Styles.wrapTabs} style={{ gap: "8px" }}>
                {Array.from({ length: 3 }).map((t, idx) => (
                  <div
                    key={idx + "-loading"}
                    className={Styles.tabs}
                    is-active={(shift === t?.value).toString()}
                    onClick={() => setShift(t?.value)}
                  >
                    <Skeleton w="60%" />
                  </div>
                ))}
              </div>
            ) : (
              <div className={Styles.wrapTabs}>
                {shifts.map((t, idx) => (
                  <div
                    key={idx}
                    className={Styles.tabs}
                    is-active={(shift === t?.value).toString()}
                    onClick={() => setShift(t?.value)}
                  >
                    <span>{t?.label}</span>
                  </div>
                ))}
              </div>
            )}
            {isOpenModalActivity && (
              <Suspense fallback={<InitialLoaderActivity />}>
                <ModalActivity
                  isTransition={isTransition}
                  isOpen
                  setOpenModalActivity={() => setIsOpenModalActivity(false)}
                  showDataModalActivity={templateData.filter(
                    (t) => t.id === isOpenModalActivity.toString()
                  )}
                  onClose={() => setIsOpenModalActivity(false)}
                />
              </Suspense>
            )}
            {isLoadingDetails || (!isLoadingDetails && templateData?.length) ? (
              <HistoryActivity
                templateData={templateData}
                onClick={setIsOpenModalActivity}
                isLoading={fixedIsLoadingDetails}
                descriptionText={(el) => (
                  <p>
                    {el?.date}&ensp;•&ensp;{el?.time}, WIB&ensp;•&ensp;
                    {el?.reportDescription}
                  </p>
                )}
              />
            ) : (
              <EmptyHandler />
            )}
            {page < totalPage && !isLoadingDetails && (
              <div
                ref={observerRef}
                style={{ height: "20px", backgroundColor: "transparent" }}
              />
            )}
            {isLoadingMore && (
              <div className={Styles.isLoadingMore}>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const EmptyHandler = () => {
  return (
    <div className={Styles.wrapEmptyHandler}>
      <img src={ImagesNoShift} alt="image-empty" />
      <div>
        <strong>Data Tidak Ditemukan</strong>
        <span>Tidak ada data dengan kriteria filter tersebut</span>
      </div>
    </div>
  );
};

const InitialLoaderActivity = ({ isScrolling = false }) => {
  return (
    <div
      className={`${Styles.wrapInitialLoaderActivity} ${
        isScrolling && Styles.scrolling
      } `}
    >
      <Spinner width={isScrolling ? "24px" : "60px"} />
    </div>
  );
};
