import { API } from "configs";

export function deleteCoordinatorExternal(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.delete(`/externalcoordinator/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
