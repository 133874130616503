import ListLocationWorkSchedule from "@Organism/WorkLocation/WorkSchedule/List";
import { getDataSchedulePerYear } from "@Services/workSchedule/getDataSchedulePerYear";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const year = params?.get("year") || new Date().getFullYear();
  const { id: locationID } = route?.params;

  const getDataSchedule = getDataSchedulePerYear(year, locationID);

  return defer({
    getDataSchedule: getDataSchedule,
  });
};

export default function ListLocationWorkSchedulePage() {
  const { getDataSchedule } = useLoaderData();
  return (
    <Suspense fallback={<ListLocationWorkSchedule isInitialLoder />}>
      <Await
        errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
        resolve={getDataSchedule}
      >
        {(data) => (
          <ListLocationWorkSchedule isInitialLoder={false} data={data} />
        )}
      </Await>
    </Suspense>
  );
}
