import React, { useRef, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import ExcelIcon from "@Assets/Images/Excel-Icon.png";
import { formatBytes } from "helpers/formatBytes";
import Toast from "@Atom/Toast";
import Lottie from "lottie-react";
import svgUploadingDoc from "@Assets/json/uploading.json";
import generateExcelTemplate from "@Assets/index";
import { uploadScheduleByExcel } from "@Services/workSchedule/uploadScheduleByExcel";
import { useNavigate, useParams } from "react-router-dom";
export default function ModalUploadWorkSchedule({
  file,
  setFile = () => {},
  isOpen,
  onClose = () => {},
  year = 2024,
  month = new Date().getMonth() + 1,
  monthString = "Januari",
  dataEmployee = [],
  getValueMonthYear = {},
}) {
  const navigate = useNavigate();

  const { id: locationID } = useParams();
  const [isLoadingPost, setLoadingPost] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const ref = useRef();
  const handleUploadFile = () => {
    ref?.current?.click();
  };
  const downloadExcel = async () => {
    await generateExcelTemplate(year, month + 1, monthString, dataEmployee);
  };
  const handleUploadExcel = async () => {
    const formData = new FormData();
    formData.append("file", file);
    setLoadingPost(true);
    try {
      const response = await uploadScheduleByExcel(formData, locationID);
      if (response.status === 201) {
        navigate(
          `/work-location/detail/${locationID}/work-schedule/detail/${getValueMonthYear.month}`,
          {
            replace: true,
          }
        );
        setLoadingPost(false);
        setIsSuccess(true);
        setFile(null);
      }
    } catch (error) {
      console.log(error);
      setLoadingPost(false);
      setIsFailed(true);
    }
  };
  setTimeout(() => {
    if (isSuccess) {
      setIsSuccess(false);
    }
    if (isFailed) {
      setIsFailed(false);
    }
  }, 5000);

  return (
    <>
      <Modal isOpen={isLoadingPost}>
        <div className={Styles.uploadingDocuments}>
          <div className={Styles.header}>
            <Icon
              icon={"document-upload"}
              color={"#2C5364"}
              size={"24"}
              className={Styles.iconHead}
            />
            <span>Sedang Proses Upload Data Jadwal Kerja</span>
          </div>
          <div className={Styles.lottieWrap}>
            <Lottie
              animationData={svgUploadingDoc}
              className={Styles.lottie}
              loop={true}
            />
          </div>
          <div className={Styles.footContain}>
            Mohon jangan tutup halaman ini, dan tunggu hingga proses upload data
            Jadwal Kerja selesai
          </div>
        </div>
      </Modal>
      <Toast
        color="green"
        text="Berhasil Mengupload Jadwal Kerja"
        isopen={isSuccess}
        onClose={() => setIsSuccess(false)}
      />
      <Toast
        color="red"
        text="Gagal Mengupload Jadwal Kerja"
        isopen={isFailed}
        onClose={() => setIsFailed(false)}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={Styles.containerModalIsEmpty}>
          <div className={Styles.headerModal}>
            <div className={Styles.between}>
              <Icon icon={"document-upload"} color={"#2C5364"} size={"24"} />
              <h2>Upload Jadwal Kerja</h2>
            </div>
            <Icon
              icon={"close-circle"}
              className={Styles.iconCloseModal}
              onClick={onClose}
            />
          </div>
          <div className={Styles.descriptionModal}>
            <p>Silahakan donwload tempalate yang tersedia</p>
          </div>
          <div
            className={Styles.textUploadModal}
            onClick={() => downloadExcel()}
          >
            <div className={Styles.headerModal}>
              <div className={Styles.between}>
                <div className={Styles.excelIcon}>
                  <img src={ExcelIcon} alt="excel-icon" />
                </div>
                <h2>Template Jadwal Bulan {monthString} </h2>
              </div>
              <Icon icon={"download"} className={Styles.iconDownload} />
              <input type="file" hidden />
            </div>
          </div>
          <div className={Styles.uploadModal} onClick={handleUploadFile}>
            {file ? (
              <div className={Styles.excelIcon}>
                <img src={ExcelIcon} alt="excel-icon" />
              </div>
            ) : (
              <Icon
                icon={"document-upload"}
                color={"#2C5364"}
                size={"48"}
                className={Styles.uploadDoc}
                style={
                  file ? { background: "#F0F0FF", padding: "8px" } : undefined
                }
              />
            )}
            {!file?.name ? <h2>Unggah Dokumen</h2> : ""}
            <p
              style={
                file?.name ? { color: "#575361", fontWeight: "500" } : undefined
              }
            >
              {file?.name ? file?.name : "Unggah dengan file .xlsx atau .csv"}
            </p>
            {file?.size && <p> {formatBytes(file?.size)} </p>}
            <Button
              text={file ? "Ubah File" : "Browse File"}
              borderColor={"#2C5364"}
              bgColor={"white"}
              color={"#2C5364"}
            />
            <input
              type="file"
              ref={ref}
              hidden
              accept=".xlsx , .csv"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <div className={Styles.buttonModal}>
            <Button
              text="Kembali"
              bgColor={"white"}
              onClick={onClose}
              color={"#2C5364"}
            />
            <Button
              text="Upload"
              isDisabled={!file ? true : false}
              onClick={() => {
                handleUploadExcel();
                onClose(!isOpen);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
