import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "@Atom/Button";
import SelectDate from "@Atom/SelectDate";
import Checkbox from "@Atom/Checkbox";
import SearchBar from "@Atom/SearchBar";
import useQuery from "@Hooks/useQuery";
import Images from "@Theme/Images";
import moment from "moment";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import { getOnDutyMembers } from "@Services/report/getOnDutyMembers";
import { useDebounce } from "@Hooks/useDebounce";
import useWindowSize from "@Hooks/useWindowSize";
import Spinner from "@Atom/Spinner";
import { EmptyHandlerForFilter } from "@Molecule/EmptyHanldes/GuardPostDetail";
import ButtonDownloadReport from "@Molecule/_modal/ButtonDownloadReport";
import useOutsideClick from "@Hooks/useOutsideClick";
import { SideBarStates } from "contexts/SideBarStates";
import Skeleton, { CircleSkeleton } from "@Atom/Skeleton";

const ModalActivity = lazy(() =>
  import("@Organism/WorkLocation/GuardPos/Detail/ModalDetail/Activity")
);
const HistoryActivity = lazy(() =>
  import("@Organism/WorkLocation/GuardPos/Detail/HistoryActivity")
);
export default function DetailReportActivity({ data, tabsAlias }) {
  const navigate = useNavigate();
  const query = useQuery();
  const { id: locationID } = useParams();

  const tabsToShow = () => {
    if (tabsAlias?.length === 0) return [];
    let listedTabs = [];
    tabsAlias?.forEach((shift) => {
      const { plotAlias: s } = shift;
      if (s === "P") {
        listedTabs.push("Pagi");
      }
      if (s === "S") {
        listedTabs.push("Siang");
      }
      if (s === "M") {
        listedTabs.push("Malam");
      }
      if (s === "NS") {
        listedTabs.push("Non-Shift");
      }
    });
    return listedTabs;
  };

  const listTabs = tabsToShow();
  const nameSessionStorage = "activeTabDetailReportPage";
  const locationName = sessionStorage.getItem("locationName");
  const { width, height } = useWindowSize();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(
    {
      id: 0,
      name: listTabs[0],
    } || JSON.parse(sessionStorage.getItem(nameSessionStorage))
  );

  const [selectedDate, setSelectedDate] = useState(new Date());
  const selectedConvertDate = moment(selectedDate)
    .format("L")
    .split("/")
    .reverse()
    .join("-");

  const [dataMembers, seDataMembers] = useState([]);
  const [searchOnDuty, setSearchOnDuty] = useState("");
  const debounceSearchMembers = useDebounce(searchOnDuty, 500);

  const templateData = useMemo(() => {
    return data?.data?.map((d) => ({
      id: d?._id,
      name: capitalizeEachWord(d?.employee?.name),
      profilePicture: d?.employee?.profilePicture || Images.AVA_DEFAULT,
      date: `${moment(d?.submittedAt).format("dddd")}, ${moment(
        d?.submittedAt
      ).format("LL")}`,
      time: `${moment(d?.submittedAt).format("LTS").split(".").join(":")} WIB`,
      shift: `${
        activeTab.id === 0 ? "Pagi" : activeTab.id === 1 ? "Sore" : "Malam"
      }`,
      activity: capitalizeEachWord(d?.guardpoint?.name), // as name location
      statusLocation: capitalizeEachWord(d?.status),
      images: d?.images,
      reportDescription: d?.description,
    }));
  }, [activeTab, data?.data]);

  const [isOpenModalActivity, setIsOpenModalActivity] = useState(false);

  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (isOpenModalActivity) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenModalActivity]);

  useEffect(() => {
    sessionStorage.setItem(nameSessionStorage, JSON.stringify(activeTab));
  }, [activeTab]);

  const [isCheckedListMembersOfDuty, setIsCheckedListMembersOfDuty] = useState(
    []
  );

  const listMembersOfDuty = useMemo(() => {
    return dataMembers?.map((m) => ({
      id: m?.ID,
      image: m?.profilePicture || Images.AVA_DEFAULT,
      name: capitalizeEachWord(m?.name) || "-",
      pos: capitalizeEachWord(m?.guardpoint) || "-",
      level: capitalizeEachWord(m?.role) || "-",
    }));
  }, [dataMembers]);

  const [toRenderMembersFiltered, setToRenderMembersFiltered] = useState([]);

  const handleCheckedListMembersOfDuty = (id) => {
    setIsCheckedListMembersOfDuty((prev) => {
      const updateCheckedListMembers = [...prev];
      updateCheckedListMembers[id] = !updateCheckedListMembers[id];

      setToRenderMembersFiltered((prevFiltered) => {
        let updatedFiltered = [...prevFiltered];

        if (updateCheckedListMembers[id]) {
          const toAppend = listMembersOfDuty.find((el) => el.id === id);
          if (toAppend && !updatedFiltered.some((el) => el.id === id)) {
            updatedFiltered = [...updatedFiltered, toAppend];
          }
        } else {
          updatedFiltered = updatedFiltered.filter((el) => el.id !== id);
        }

        return updatedFiltered;
      });

      return updateCheckedListMembers;
    });
  };

  const [isCheckedFilterStatus, setIsCheckedFilterStatus] = useState([
    false,
    false,
    false,
  ]);

  const handleCheckedFilterStatus = (idx) => {
    setIsCheckedFilterStatus((prev) => {
      const updatedStatus = [...prev];
      updatedStatus[idx] = !updatedStatus[idx];
      return updatedStatus;
    });
  };

  const [isLoadingGetMembers, setIsLoadingGetMembers] = useState(true);
  useEffect(() => {
    const getAllDataMembersForFilters = async () => {
      setIsLoadingGetMembers(true);
      try {
        const res = await getOnDutyMembers(
          "ACTIVITY",
          locationID,
          selectedConvertDate,
          debounceSearchMembers
        );
        if (res.status === 200) {
          seDataMembers(res.data?.data);
        }
      } catch (error) {
        console.log("Error get data members detail report activity: ", error);
      } finally {
        setIsLoadingGetMembers(false);
      }
    };
    getAllDataMembersForFilters();
  }, [locationID, debounceSearchMembers, selectedConvertDate]);

  const { isOpenSideBar } = useContext(SideBarStates);

  const page = 1;
  const [limit, setLimit] = useState(10);
  const dateQuery = query.get("date");
  const limitQuery = +query.get("limit");
  const shiftQuery = query.get("shift");

  const statusQuery = query.getAll("status");
  // const employeeIDQuery = query.getAll("employeeID");

  const status = isCheckedFilterStatus
    .map((s, idx) => {
      if (s) {
        return idx === 0 ? "AMAN" : idx === 1 ? "SIAGA" : "WASPADA";
      }
      return null;
    })
    .filter(Boolean);

  const isStatusChanged =
    status.length !== statusQuery.length ||
    status.some((s) => !statusQuery.includes(s));

  useEffect(() => {
    const status = isCheckedFilterStatus
      .map((s, idx) => {
        if (s) {
          return idx === 0 ? "AMAN" : idx === 1 ? "SIAGA" : "WASPADA";
        }
        return null;
      })
      .filter(Boolean)
      .map((status) => `&status=${status}`)
      .join("");

    const employeesID = toRenderMembersFiltered
      .map((l) => `&employeeID=${l.id}`)
      .join("");

    navigate(
      `${pathname}?page=${page}&limit=${limit}&locationID=${locationID}&date=${selectedConvertDate}${status}${employeesID}&shift=${
        activeTab.id === 0 ? "P" : activeTab.id === 1 ? "S" : "M"
      }`
    );
  }, [
    activeTab,
    isCheckedFilterStatus,
    limit,
    toRenderMembersFiltered,
    locationID,
    navigate,
    pathname,
    selectedConvertDate,
  ]);

  const listInnerRef = useRef(null);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data?.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data?.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  const loadingState = () => {
    if (
      selectedConvertDate !== dateQuery ||
      shiftQuery !==
        (activeTab.id === 0 ? "P" : activeTab.id === 1 ? "S" : "M") ||
      isStatusChanged
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);

  const refBox = useRef(null);
  const isOutsideClick = useOutsideClick(refBox);

  useEffect(() => {
    if (isOutsideClick && isOpenModalDownload) {
      setIsOpenModalDownload(false);
    }
  }, [isOpenModalDownload, isOutsideClick]);

  return (
    <div className={Styles.containerDetailReportActivity}>
      <header className={`${Styles.header}`} style={{ position: "relative" }}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/report/activity")}
            className={Styles.back}
          />
          <h3>{locationName}</h3>
        </div>
        <Button
          isAddButton
          isLeftIcon
          icon={"download"}
          style={{
            background: "transparent",
            color: data?.data?.length === 0 ? "#BBC3CE" : "#2C5364",
          }}
          colorIcon={data?.data?.length === 0 ? "#BBC3CE" : "#2C5364"}
          text="Download Report"
          onClick={() => setIsOpenModalDownload(!isOpenModalDownload)}
          isDisabled={data?.data?.length === 0}
        />
        {isOpenModalDownload && (
          <ButtonDownloadReport
            type={"activities"}
            date={selectedConvertDate}
            month={moment(selectedDate).format("MMMM")}
            year={moment(selectedDate).format("YYYY")}
            ref={refBox}
            shift={activeTab?.name?.split("Shift ")[1]}
          />
        )}
      </header>
      <div className={Styles.wrapViewContain}>
        <div className={Styles.left}>
          <div>
            <SelectDate
              setValue={setSelectedDate}
              value={selectedDate}
              autoSave
              // [must update month & year ]
            />
            <div className={Styles.divider}></div>
            <div className={Styles.selectStatusLocation}>
              <strong>Status Lokasi</strong>
              {["Aman", "Siaga", "Waspada"].map((c, idx) => (
                <div key={idx}>
                  <Checkbox
                    isChecked={isCheckedFilterStatus[idx]}
                    setIsChecked={() => handleCheckedFilterStatus(idx)}
                  />
                  <span>{c}</span>
                </div>
              ))}
            </div>
            <div className={Styles.divider}></div>
          </div>

          <div className={Styles.listPersonil}>
            <strong>Daftar Anggota Bertugas</strong>
            <SearchBar
              size="s"
              placeholder="Cari Anggota"
              setValue={setSearchOnDuty}
              value={searchOnDuty}
            />
            <div
              className={`${Styles.selectStatusLocation} ${Styles.overflow}`}
            >
              {isLoadingGetMembers
                ? Array.from({ length: 3 })?.map((l, idx) => (
                    <div key={idx + "-skeleton"}>
                      <Checkbox
                        setIsChecked={() =>
                          handleCheckedListMembersOfDuty(l?.id)
                        }
                        isChecked={isCheckedListMembersOfDuty[l?.id]}
                      />
                      <div className={Styles.profile} style={{ width: "100%" }}>
                        <CircleSkeleton />
                        <Skeleton w="100%" />
                      </div>
                    </div>
                  ))
                : listMembersOfDuty?.map((l, idx) => (
                    <div key={idx}>
                      <Checkbox
                        setIsChecked={() =>
                          handleCheckedListMembersOfDuty(l?.id)
                        }
                        isChecked={isCheckedListMembersOfDuty[l?.id]}
                      />
                      <div className={Styles.profile}>
                        <img src={l?.image} alt="profile-picture" />
                        <div>
                          <strong>{l?.name}</strong>
                          <span>
                            {l?.level} • {l?.pos}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div
          className={`${Styles.right} ${
            toRenderMembersFiltered.length > 0 && Styles.rowGap
          }`}
        >
          <div
            className={`${Styles.wrapSelectedPersonil} ${
              toRenderMembersFiltered.length > 0 && Styles.selectedFilter
            } `}
            style={{
              maxWidth: "1080px",
              width: `calc(${width}px - ${isOpenSideBar ? 680 : 422}px )`,
              overflow: "auto",
            }}
          >
            {toRenderMembersFiltered?.map((m, idx) => (
              <Button
                key={`members-checked-${idx}`}
                isNoBorder
                style={{
                  background: "#E6F0F4",
                  color: idx === 20 ? "red" : "#2C5364",
                  borderRadius: "20px",
                  flexShrink: "0",
                }}
                onClickIcon={() => {
                  setToRenderMembersFiltered((prev) =>
                    prev.filter((el) => el?.id !== m?.id)
                  );
                  setIsCheckedListMembersOfDuty((prev) => {
                    const updatedCheckedList = [...prev];
                    updatedCheckedList[m.id] = false; // Set isChecked ke false
                    return updatedCheckedList;
                  });
                }}
                icon={"close-circle"}
                iconSize={"10"}
                colorIcon={"#2C5364"}
                text={m?.name}
              />
            ))}
          </div>

          <div className={Styles.rightContain}>
            <div className={Styles.wrapTabs}>
              {isLoadingGetMembers
                ? Array.from({ length: 3 }).map((t, idx) => (
                    <div
                      key={idx}
                      className={Styles.tabs}
                      is-active={(activeTab.id === idx).toString()}
                      onClick={() => setActiveTab({ id: idx, name: t })}
                    >
                      <Skeleton w="60%" />
                    </div>
                  ))
                : listTabs.map((t, idx) => (
                    <div
                      key={idx}
                      className={Styles.tabs}
                      is-active={(activeTab.id === idx).toString()}
                      onClick={() => setActiveTab({ id: idx, name: t })}
                    >
                      <span>{t}</span>
                    </div>
                  ))}
            </div>

            {data?.data?.length === 0 ? (
              <EmptyHandlerForFilter />
            ) : (
              <div
                style={{
                  height: `calc(${height}px - ${
                    toRenderMembersFiltered.length > 0 ? 195 + 48 : 195
                  }px)`,
                  overflowY: "auto",
                }}
                ref={listInnerRef}
                onScroll={onScroll}
              >
                <Suspense fallback={<HistoryActivity isLoading />}>
                  <HistoryActivity
                    templateData={templateData}
                    onClick={setIsOpenModalActivity}
                    isLoading={loadingState()}
                  />
                </Suspense>

                {+limitQuery !== limit && <InitialLoaderActivity isScrolling />}

                {isOpenModalActivity && (
                  <Suspense fallback={<InitialLoaderActivity />}>
                    <ModalActivity
                      isTransition={isTransition}
                      isOpen
                      setOpenModalActivity={() => setIsOpenModalActivity(false)}
                      showDataModalActivity={templateData.filter(
                        (t) => t.id === isOpenModalActivity.toString()
                      )}
                      onClose={() => setIsOpenModalActivity(false)}
                    />
                  </Suspense>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const InitialLoaderActivity = ({ isScrolling = false }) => {
  return (
    <div
      className={`${Styles.wrapInitialLoaderActivity} ${
        isScrolling && Styles.scrolling
      } `}
    >
      <Spinner width={isScrolling ? "24px" : "60px"} />
    </div>
  );
};
