import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import { fileBaseUrl } from "configs";
import Geocode from "react-geocode";
import useWindowSize from "@Hooks/useWindowSize";
export default function ModalAddPatrolPoint({
  isOpen = false,
  fileRef = null,
  onSubmit = () => {},
  onClose = () => {},
  isFilled = false,
  inputLocation = {
    image: "",
    name: "",
    latitude: "",
    longitude: "",
  },
  setInputLocation = () => {},
  selectedLocation,
  setSelectedLocation,
}) {
  const { height } = useWindowSize();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("id");
  Geocode.setRegion("ID");

  useEffect(() => {
    const setDefaultLocation = async () => {
      if (!selectedLocation?.latitude || !selectedLocation?.longitude) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            setSelectedLocation({
              latitude,
              longitude,
            });

            Geocode.fromLatLng(latitude, longitude).then(
              (_) => {},
              (error) => {
                console.error("Geocode error", error);
              }
            );
          },
          (error) => {
            console.error("Geolocation error", error);
            setSelectedLocation({
              latitude: -6.2,
              longitude: 106.816666,
            });
          }
        );
      } else {
        Geocode.fromLatLng(
          selectedLocation.latitude,
          selectedLocation.longitude
        ).then(
          (_) => {},
          (error) => {
            console.error("Geocode error", error);
          }
        );
      }
    };

    setDefaultLocation();
  }, [selectedLocation, setSelectedLocation]);

  const handleChangeInput = (name, value, id) => {
    if (id) {
      setInputLocation((prev) => ({
        ...prev,
        fieldPosInventory: prev.fieldPosInventory.map((el) =>
          id === el.id
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      }));
    } else {
      setInputLocation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [prevImage, setPrevImage] = useState();
  useEffect(() => {
    setPrevImage(inputLocation.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidateEmpty = () => {
    const { name, image } = inputLocation;
    const { latitude, longitude } = selectedLocation;
    if (!name || !image || !latitude || !longitude) {
      return true;
    }
    return false;
  };

  const handleApplyCoordinate = () => {
    const { latitude, longitude } = inputLocation;
    if (latitude && longitude)
      setSelectedLocation((prev) => ({
        ...prev,
        latitude: +latitude,
        longitude: +longitude,
      }));
  };

  useEffect(() => {
    setInputLocation((c) => ({
      ...c,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    }));
  }, [selectedLocation.latitude, selectedLocation.longitude, setInputLocation]);

  const [isTransition, setTransition] = useState(false);
  if (isFilled || isOpen) {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={Styles.modalAddWorkLocation}
        is_transition={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          <strong>{isFilled ? "Pengaturan Lokasi" : "Tambah Pos Jaga"}</strong>
          <Icon
            icon={"close-circle"}
            onClick={onClose}
            className={Styles.closeCircle}
          />
        </div>
        <div className={Styles.uploadLocation}>
          <strong>
            Foto Titik Patroli <span>*</span>{" "}
          </strong>
          <div className={Styles.chooseFile}>
            <input
              type="file"
              hidden
              accept=".png, .jpg, .jpeg"
              ref={fileRef}
              onChange={(e) => handleChangeInput("image", e.target.files[0])}
            />

            {inputLocation?.image ? (
              <img
                alt="image-location"
                onClick={() => fileRef.current.click()}
                src={
                  typeof inputLocation?.image === "object" &&
                  isFilled &&
                  prevImage !== inputLocation?.image
                    ? URL.createObjectURL(inputLocation?.image)
                    : isFilled && typeof inputLocation?.image === "string"
                    ? fileBaseUrl + inputLocation?.image
                    : URL.createObjectURL(inputLocation?.image)
                }
              />
            ) : (
              <>
                <input
                  type="file"
                  hidden
                  accept=".png, .jpg, .jpeg"
                  ref={fileRef}
                  onChange={(e) =>
                    handleChangeInput("image", e.target.files[0])
                  }
                />
                <Icon icon={"document-upload"} color={"#2C5364"} size={"48"} />
                <div className={Styles.viewerCol}>
                  <span>Unggah Titik Lokasi Patroli</span>
                  <p>Unggah file dengan format .JPEG, PNG & JPG </p>
                </div>
                <Button
                  text="Browse File"
                  onClick={() => fileRef.current.click()}
                />
              </>
            )}
          </div>
        </div>
        <div className={Styles.inputField}>
          <InputField
            setValue={(v) => handleChangeInput("name", v)}
            textLabel="Deskripsi Titik Patroli"
            isLabel
            isRequired
            placeholder="Contoh: Lantai 1 Lobby Utama"
            value={inputLocation.name}
          />
          <div className={Styles.coordinatInput}>
            <InputField
              setValue={(e) =>
                handleChangeInput("latitude", e.replace(/[^0-9.-]/g, ""))
              }
              textLabel="Latitude"
              isLabel
              placeholder="Masukkan titik koordinat latitude"
              value={inputLocation.latitude}
              onKeyDown={(e) =>
                e.key === "Enter" && inputLocation.latitude
                  ? handleApplyCoordinate()
                  : {}
              }
            />
            <InputField
              setValue={(e) =>
                handleChangeInput("longitude", e.replace(/[^0-9.]/g, ""))
              }
              textLabel="Longitude"
              isLabel
              placeholder="Masukkan titik koordinat longitude"
              value={inputLocation.longitude}
              onKeyDown={(e) =>
                e.key === "Enter" && inputLocation.longitude
                  ? handleApplyCoordinate()
                  : {}
              }
            />
            <div className={Styles.btnCoord}>
              <Button
                text="Submit"
                isDisabled={!inputLocation.latitude || !inputLocation.longitude}
                onClick={() => handleApplyCoordinate()}
              />
            </div>
          </div>
          <div
            className={Styles.containerMap}
            style={{ height: `calc(${height}px - 400px)` }}
          >
            <Map
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              isAutoComplete
              coordinate={{
                latitude: +selectedLocation?.latitude,
                longitude: +selectedLocation?.longitude,
              }}
            />
          </div>
        </div>
        <div className={Styles.footerModal}>
          <Button
            text="Simpan Titik Patroli"
            isDisabled={handleValidateEmpty()}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}
