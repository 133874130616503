import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import bold from "@Assets/Icon/bold.svg";
import italic from "@Assets/Icon/italic.svg";
import underline from "@Assets/Icon/underline.svg";
import leftAlign from "@Assets/Icon/left-align.svg";
import centerAlign from "@Assets/Icon/center-align.svg";
import ordered from "@Assets/Icon/ordered.svg";
import unordered from "@Assets/Icon/unordered.svg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function TextEditor({
  value = "",
  setValue = () => {},
  type = "full",
  isSticky = false,
  placeholder = "Deskripsi...",
}) {
  const getInitialState = (content) => {
    const blocksFromHtml = htmlToDraft(`${content}`);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );
    return EditorState.createWithContent(contentState);
  };
  const [editorState, setEditorState] = useState(getInitialState());

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (html !== value) {
      setEditorState(getInitialState(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(html);
  };

  const handlePastedText = (pasteText, currentContentState) => {
    if (!currentContentState || !currentContentState.getPlainText) {
      console.error(
        "currentContentState is invalid or missing getPlainText method"
      );
      return "not-handled";
    }
    const currentContent = currentContentState.getPlainText();
    const newText = currentContent + pasteText;

    const newContentState = ContentState.createFromText(newText);

    const newEditorState = EditorState.createWithContent(newContentState);

    setEditorState(newEditorState);

    return "handled";
  };

  const toolbarComponent = useMemo(() => {
    if (type === "full") {
      return {
        options: ["inline", "textAlign", "list", "history"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
          bold: { icon: bold, className: Styles.toolbarIcon },
          italic: { icon: italic, className: Styles.toolbarIcon },
          underline: { icon: underline, className: Styles.toolbarIcon },
        },

        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["ordered", "unordered"],
          ordered: { icon: ordered, className: Styles.toolbarIcon },
          unordered: { icon: unordered, className: Styles.toolbarIcon },
        },
        textAlign: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ["left", "center"],
          left: { icon: leftAlign, className: Styles.toolbarIcon },
          center: { icon: centerAlign, className: Styles.toolbarIcon },
        },
        history: { inDropdown: false },
      };
    } else {
      return {
        options: ["inline"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
          bold: { icon: bold, className: Styles.toolbarIcon },
          italic: { icon: italic, className: Styles.toolbarIcon },
          underline: { icon: underline, className: Styles.toolbarIcon },
        },
      };
    }
  }, [type]);

  return (
    <div className={Styles.editorWrapper}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName={Styles.editor}
        toolbarClassName={`${Styles.toolbar} ${isSticky ? Styles.sticky : ""}`}
        toolbar={toolbarComponent}
        placeholder={placeholder}
        handlePastedText={handlePastedText}
      />
    </div>
  );
}
