import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import useOutsideClick from "@Hooks/useOutsideClick";
import moment from "moment";
import useQuery from "@Hooks/useQuery";
import Skeleton from "@Atom/Skeleton";
export default function ListLocationWorkSchedule({
  data,
  isInitialLoder = false,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");
  const activeFilterYear = +sessionStorage.getItem("yearScheduleWorkLocation");

  // const [sortMonths, setSortMonths] = useState({
  //   type: "DESC" || "ASC",
  //   column: "month",
  // });

  // const sortedDataMonth = useMemo(() => {
  //   const sorted = [...data?.data].sort((a, b) => {
  //     if (sortMonths.column) {
  //       if (sortMonths.type === "ASC") {
  //         return a.month?.localeCompare(b.month);
  //       } else {
  //         return b.month?.localeCompare(a.month);
  //       }
  //     }
  //     return 0;
  //   });

  //   return sorted;
  // }, [data?.data, sortMonths.column, sortMonths.type]);

  const template = useMemo(() => {
    return {
      data:
        data?.data?.map((l, idx) => ({
          id: idx,
          month: l?.month,
          amountInMorning: l?.counts.P,
          amountInAfternoon: l?.counts.S,
          amountInEvening: l?.counts.M,
          amountOff: l?.counts.OFF,
          amountNonShift: l?.counts.NS,
          amountPersonil: "-",
          status: l?.isUploaded ? "Sudah Diatur" : "Belum Diatur",
        })) || [],

      columns: [
        {
          name: "month",
          title: (
            <div className={Styles.centerAlign}>
              <span>{"Bulan"}</span>
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.month}</span>
            </div>
          ),
        },
        {
          name: "amountInMorning",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Masuk Pagi" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInMorning}</span>
            </div>
          ),
        },
        {
          name: "amountInAfternoon",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Masuk Siang" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInAfternoon}</span>
            </div>
          ),
        },
        {
          name: "amountInEvening",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Masuk Malam" />
            </div>
          ),
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountInEvening}</span>
            </div>
          ),
        },
        {
          name: "amountOff",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Off" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountOff}</span>
            </div>
          ),
        },

        {
          name: "",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Non Shift" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountNonShift}</span>
            </div>
          ),
        },
        {
          name: "amountPersonil",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Jml Anggota" />
            </div>
          ),
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.centerAlign}>
              <span>{row.amountPersonil}</span>
            </div>
          ),
        },
        {
          name: "status",
          width: "150px",
          title: (
            <div className={Styles.centerAlign}>
              <TabHeader text="Status" />
            </div>
          ),
          renderData: (row) =>
            isInitialLoder ? (
              <Skeleton />
            ) : (
              <div className={Styles.status} status={row.status.toLowerCase()}>
                <span>{row?.status}</span>
              </div>
            ),
          hideOnMobile: true,
        },
      ],
    };
  }, [data, isInitialLoder]);

  const refYear = useRef(null);
  const outsideClick = useOutsideClick(refYear);
  const [isOpenFilterListYear, setIsOpenFilterListYear] = useState(false);

  useEffect(() => {
    if (isOpenFilterListYear && outsideClick) {
      setIsOpenFilterListYear(false);
    }
  }, [isOpenFilterListYear, outsideClick]);

  const generateYearList = () => {
    const currentYear = moment().year();
    const endYear = currentYear + 4;

    return Array.from(
      { length: endYear - currentYear + 1 },
      (_, i) => currentYear + i
    );
  };

  const listYears = generateYearList();
  listYears.unshift(2024);

  const [selectedYear, setSelectedYear] = useState(
    activeFilterYear || listYears
  );

  useEffect(() => {
    const currentYear = moment().year();
    if (selectedYear !== currentYear) {
      setSelectedYear(currentYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.setItem("yearScheduleWorkLocation", selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    navigate(`${pathname}?query=q&year=${selectedYear}`, { replace: true });
  }, [navigate, pathname, selectedYear]);

  return (
    <div className={Styles.wrapperListWorkSchedule}>
      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/work-location")}
            className={Styles.back}
          />
          <h3>{nameLocation}</h3>
        </div>
        <div
          className={Styles.selectedDate}
          onClick={() => setIsOpenFilterListYear(!isOpenFilterListYear)}
          is_open={isOpenFilterListYear.toString()}
        >
          <Icon icon={"calendar"} className={Styles.settings} />
          <span>{selectedYear}</span>
          {isOpenFilterListYear && (
            <div className={Styles.wrapFilterYear} ref={refYear}>
              {listYears.map((el, idx) => (
                <span
                  is_active={(el === selectedYear).toString()}
                  onClick={() => setSelectedYear(el)}
                  key={idx}
                >
                  {el}
                </span>
              ))}
            </div>
          )}
        </div>
      </header>
      <Table
        className={Styles.tableRadius}
        data={template.data}
        columns={template.columns}
        withNumbering
        widthNumber="20px"
        onClickRow={(row) => {
          sessionStorage.setItem("selectedMonthNumber", row.id);
          navigate(`detail/${row.month}`);
        }}
        classNameCustomTh={Styles.thSticky}
        classNameCustomTd={Styles.tdSticky}
        isLoading={+query.get("year") !== selectedYear || isInitialLoder}
        limit={12}
      />
    </div>
  );
}
