import { API } from "configs";

export function getDetailClient() {
  return new Promise(async (resolve, reject) => {
    const currentClient = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const { data } = await API.get(`/client/${currentClient?.code}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}
