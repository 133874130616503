import WithBg from "@Atom/WIthBg";
import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import DetailClient from "@Organism/DetailClient";
import SkeletonDetailClientPage from "@Organism/Skeletons/DetailClientPage";
import { fetchAllCoordinatorExternal } from "@Services/clients/fetchAllCoordinatorExternal";
import { getClientContact } from "@Services/clients/getClientContact";
import { getClientPIC } from "@Services/clients/getClientPIC";
import { getDetailClient } from "@Services/clients/getDetailClient";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = () => {
  const detailClient = getDetailClient();
  const clientPIC = getClientPIC(1, 10);
  const clientContact = getClientContact(1, 10);
  const externalCoordinator = fetchAllCoordinatorExternal(1, 10);

  return defer({
    getAllDetailClientPage: Promise.all([
      detailClient,
      clientPIC,
      clientContact,
      externalCoordinator,
    ]).then(([cDetail, cPIC, cContact, cExt]) => ({
      cDetail,
      cPIC,
      cContact,
      cExt,
    })),
  });
};

export default function DetailClientPage() {
  const { getAllDetailClientPage } = useLoaderData();
  return (
    <WithBg>
      <Suspense fallback={<SkeletonDetailClientPage />}>
        <Await
          errorElement={<AsyncErrorPage height="calc(100vh - 56px)" />}
          resolve={getAllDetailClientPage}
        >
          {({ cDetail, cPIC, cContact, cExt }) => {
            return (
              <DetailClient
                datasProfileClient={cDetail?.data?.data}
                datasPIC={cPIC?.data?.data}
                datasContact={cContact?.data?.data}
                datasExtCoor={cExt?.data}
              />
            );
          }}
        </Await>
      </Suspense>
    </WithBg>
  );
}
