import { API } from "configs";

export function getAllAnnouncements(page = 1, limit = 10, searchQuery = "") {
  return new Promise(async (resolve, reject) => {
    const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const response = await API.get(
        `/announcement/?clientCode=${CLIENT_KEY.code}&page=${page}&limit=${limit}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
